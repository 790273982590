import React, { useEffect, useMemo, useRef } from "react";
import { getService } from "react-in-angularjs";
import L, { LatLngExpression, LatLngTuple } from "leaflet";
import { react2angular } from "react2angular";
import PropTypes from "prop-types";

import { MAPBOX_USER, MAPBOX_STYLE_ID, MAPBOX_TILE_SIZE, MAPBOX_HIGHDPI, MAPBOX_ACCESS_TOKEN } from "../../../app/configuration/index";
import { withReduxProvider } from "../../services/withReduxProvider";
import { MapSelectionActionsBar } from "./MapSelectionActionsBar";
import { useAppSelector } from "../../hooks/useAppSelector";
import { MapBackendActions } from "./MapBackendActions";
import { ReportDownloader } from "./ReportDownloader";

interface EpiMapProps {
  tiles?: string;
  scale?: boolean;
  contextMenuEnabled?: boolean;
  isPublicPage?: boolean;
  zoom?: number;
  center?: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInit?: (leafletMap: any) => void;
  actions?: IDefaultActions;
  reload?: () => void;
}

export interface IDefaultActions {
  clear: () => void;
  goTo: () => void;
  filterBy: () => void;
  combine: () => void;
}

const defaultZoom = 5;
const defaultCenter: LatLngTuple = [38.23818, -97.866211];

export const EpiMap: React.FC<EpiMapProps & JSX.IntrinsicAttributes> = ({ tiles, scale, contextMenuEnabled = true, isPublicPage, center, zoom, onInit: init, actions, reload }) => {
  const TruMap = getService("TruMap");
  const Storage = getService("Storage");

  zoom = zoom != undefined ? zoom : Storage.getItem("mapZoom");
  center = center != undefined ? center : Storage.getItem("mapCenter");

  const { entityID } = useAppSelector((state) => state.app);

  const mapRef = useRef<HTMLDivElement | null>(null);
  const { map, selectedFeatures } = useAppSelector((state) => state.pages.truterritory);

  useEffect(() => {
    const leafletMap = new TruMap(
      L.map(mapRef.current!, {
        center: getCenter(center),
        zoom: zoom ?? defaultZoom,
        zoomAnimationThreshold: 15,
        maxBounds: [
          [-90, -190],
          [90, 190],
        ],
        minZoom: 1,
        boxZoom: false,
      }),
      isPublicPage
    );

    // set the base tile layer
    leafletMap.addMapBoxTileLayer(tiles || MAPBOX_USER, MAPBOX_STYLE_ID, MAPBOX_TILE_SIZE, MAPBOX_HIGHDPI, MAPBOX_ACCESS_TOKEN);

    if (scale) leafletMap.addScale();

    // Enable default context menu
    if (contextMenuEnabled) {
      leafletMap.enableContextMenu();
    }

    // run init
    if (init) init(leafletMap);

    // save zoom & center on reload and on tab change
    document.addEventListener("visibilitychange", saveZoomAndCenter.bind(null, leafletMap));

    // save zoom & center on component unmount and remove the above event listener
    return () => {
      saveZoomAndCenter(leafletMap);
      document.removeEventListener("visibilitychange", saveZoomAndCenter.bind(null, leafletMap));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  /**
   * Put the map's zoom and center into local storage
   * @return {void}
   */
  function saveZoomAndCenter(truMap: { getZoom: () => number; getCenter: () => LatLngExpression }) {
    Storage.setItem("mapZoom", truMap.getZoom() < 3 ? 3 : truMap.getZoom(), entityID);
    Storage.setItem("mapCenter", truMap.getCenter(), entityID);
  }
  /**
   * This function is used to figure out what the center of the map should be set to,
   *   the center prop should be a LatLngExpression but it is not typed as such,
   *   since it's in localStorage and interfaced with AngularJS
   * @param center unknown but should be LatLngExpression
   */
  const getCenter = (center: unknown | null): LatLngExpression => {
    if (!center) return defaultCenter;

    try {
      const parsedCenter = L.latLng(center as LatLngExpression);
      if (parsedCenter && parsedCenter.lat && parsedCenter.lng) return parsedCenter;
    } catch {
      return defaultCenter;
    }
    return defaultCenter;
  };

  const [featureCount, layerCount] = useMemo(() => {
    let featureCount = 0,
      layerCount = 0;

    if (selectedFeatures) {
      for (const layerName in selectedFeatures) {
        layerCount++;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const _featureKey in selectedFeatures[layerName]) {
          featureCount++;
        }
      }
    }

    return [featureCount, layerCount];
  }, [selectedFeatures]);

  return (
    <>
      <div id="map" style={{ width: "100%", height: "100%" }} ref={mapRef} />
      {/* We'll load the MapBackendActions component here, but it really could go anywhere. */}
      {actions && <MapBackendActions clear={actions.clear} reload={reload}></MapBackendActions>}
      {/* Same for the report invocation piece. */}
      <ReportDownloader />
      {featureCount && map && (
        <div className="selection-status">
          <span className="counts">
            {featureCount} feature{featureCount != 1 && "s"} selected from {layerCount} layer{layerCount != 1 && "s"}.
          </span>
          {actions && <MapSelectionActionsBar mapID={map.ID} selectedFeatures={selectedFeatures ?? {}} defaultActions={actions}></MapSelectionActionsBar>}
        </div>
      )}
    </>
  );
};

EpiMap.propTypes = {
  tiles: PropTypes.string,
  scale: PropTypes.bool,
  contextMenuEnabled: PropTypes.bool,
  isPublicPage: PropTypes.bool,
  onInit: PropTypes.func,
  actions: PropTypes.any,
  reload: PropTypes.func,
};

export const AngularEpiMap = react2angular(withReduxProvider(EpiMap));
