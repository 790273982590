import { CollectionType } from "./CollectionTypes";
import { IMap } from "./IMap";

export interface ICollection {
  ID: number;
  entityID: number;
  name: string;
  tableName: string;
  type: CollectionType;
  sql: string;
  aggSql: string;
  properties: Property[];
  indexes: Index[];
  geometries: Geometry[];
  aggregates: null | Property[];
  dateColumn: null;
  dateRange: null;
  archived: boolean;
  refreshEnabled: boolean;
  createdAt: Date;
  updatedAt: Date;
  geometryType: string;
  featureColumns: string[];
  themes: IThemeWithID[];
  idColName: string | null;
  noun?: string;
  srid?: number;

  // used when saving an existing collection
  merge?: boolean;

  // restangular properties
  findTheme?: (map: IMap, preferMapLabel: boolean) => ITheme;
}

export interface ICollectionsMeta {
  acceptFiles: string[];
  count: number;
  fileTypes: Record<string, string[]>[];
  fileTypesForHumans: string[];
}

export interface Geometry {
  srid: number;
  type: string;
  column: string;
  extent: string;
}

export interface Index {
  unique: boolean;
  columns: IndexColumn[];
}

export interface IndexColumn {
  column: string;
  order?: string | null;
  name: string;
  geomType?: string | null;
  srid?: number | null;
  type: string;
}

export interface Property {
  name: string;
  type: Type;
  column: string;

  hidden?: boolean;
  protected?: boolean;
  required?: boolean;
  system?: boolean;
  allowedValues?: string[];
}

export enum Type {
  Float8 = "float8",
  Int4 = "int4",
  Timestamptz = "timestamptz",
  Varchar = "varchar",
  Text = "text",
}

export interface ITheme {
  ID?: number;
  collectionID: number;
  workspaceID: number;
  name: string;
  legend: IThemeLegend[];
  showLegendHeader: boolean;
  customLegendHeader: null | string;
  label: string | null;
  customLabel: null | string;
  labelFormats: Record<string, string>;
  labelZeroes: Record<string, boolean>;
  fontSize: number;
  css: string;
  previewFeatures: boolean;
}

export interface IThemeWithID extends ITheme {
  ID: number;
}

export interface IThemeLegend {
  type: ThemeLegendType;
  text: string;
  value: string;
  aggFormat: string;
}

export enum ThemeLegendType {
  "Line Color" = "line-color",
  "Line Width" = "line-width",
  "Polygon Fill" = "polygon-fill",
  "Marker Fill" = "marker-fill",
  "Aggregate" = "aggregate",
}
