import { IMetaPagination } from "../types/IApiResponse";

export const Paging = ({ onChange, pages, verbosity = 1 }: { onChange?: (page: number) => void; verbosity?: 1 | 2 | 3; pages: IMetaPagination }) => (
  <span className="epi-paging">
    {(() => {
      switch (verbosity) {
        case 1:
          return (
            <>
              <span className="page-selector">
                {pages.page > 1 && (
                  <span className="prev" onClick={() => onChange && onChange(pages.page - 1)}>
                    &lt;prev
                  </span>
                )}
                <span className="page">
                  Page {pages.page} of {pages.totalPages}
                </span>
                {pages.page < pages.totalPages && (
                  <span className="next" onClick={() => onChange && onChange(pages.page + 1)}>
                    next&gt;
                  </span>
                )}
              </span>
            </>
          );
        case 2:
          return (
            <>
              <span className="info">Records: {pages.total}</span>
              <span className="page-selector">
                <span className="prev">&lt;prev</span>
                <span className="page">
                  Page {pages.page} of {pages.totalPages}
                </span>
                <span className="next">next&gt;</span>
              </span>
            </>
          );
        case 3:
          return (
            <>
              <span className="info">
                Pages: {pages.totalPages} Records: {pages.total}
              </span>
              <span className="records">
                Records
                <span>
                  {pages.range[0]} - {pages.range[1]}
                </span>
              </span>
              <span className="page-selector">
                <span className="prev">&lt;prev</span>
                <span className="page">Page {pages.page}</span>
                <span className="next">next&gt;</span>
              </span>
            </>
          );
      }
    })()}
  </span>
);
