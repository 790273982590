import { IStateService } from "angular-ui-router";
import { getService } from "react-in-angularjs";
import { react2angular } from "react2angular";

import { useAppSelector } from "../hooks/useAppSelector";
import { withReduxProvider } from "../services/withReduxProvider";
import { useEffect, useState } from "react";

export const Menu = () => {
  const Menu = getService("Menu");
  const PermissionManager = getService("PermissionManager");
  const $state: IStateService = getService("$state");
  const Session = getService("Session");

  const { entityID: entityId, activePage, session } = useAppSelector((state) => state.app);

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    Session.startPolling();
  }, []);

  useEffect(() => {
    if (session?.permissions) {
      Menu.setPermissionsAndEntity(new PermissionManager(session.permissions), entityId);
      setMenu(Menu.getEnabled().filter((i: { visible: boolean }) => i.visible !== false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, entityId]);

  if (menu?.length <= 1) return <></>; // if we only have 1 menu item, we don't need a menu

  return (
    <nav id="side-nav-left" className="col-xs-2">
      <ul className="nav nav-pills nav-stacked nav-collapse">
        {
          // Menu.get()
          // .filter((i: { visible: boolean }) => i.visible !== false)
          menu?.map((item: { id: string }, index: number) => (
            <li
              className={[item.id, item.id === activePage ? "active" : "", Boolean(entityId) ? "" : "disabled"].join(
                " "
              )}
              key={index}
            >
              <a
                href={$state.href(item.id, { entityID: entityId })}
                onClick={(e) => !Boolean(entityId) && e.preventDefault()}
              ></a>
            </li>
          ))
        }
      </ul>
    </nav>
  );
};

export const AngularMenu = react2angular(withReduxProvider(Menu));
