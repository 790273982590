import { react2angular } from "react2angular";
import { getService } from "react-in-angularjs";

import { updateGlobalState } from "../slices/appSlice";
import { Content } from "../design/layout/Content";
import { useEffect, useState } from "react";
import { withReduxProvider } from "../services/withReduxProvider";
import { useAppSelector } from "../hooks/useAppSelector";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { DOCS_ROOT } from "../../app/configuration";

export const Home = () => {
  const dispatch = useAppDispatch();
  const Menu = getService("Menu");
  const PermissionManager = getService("PermissionManager");
  const $state = getService("$state");
  const [accessWarning, setAccessWarning] = useState("");

  useEffect(() => {
    dispatch(
      updateGlobalState({
        title: "Home",
        h1: "Home",
        activeSection: "home",
        activePage: "home",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { session, entityID } = useAppSelector((state) => state.app);

  // If the `docs` param is set in the query string, authorize
  // the user and send them to the docs site at the given path.
  useEffect(() => {
    if ($state.params.docs && session?.docs_jwt) {
      fetch(`${DOCS_ROOT}/authorize`, { credentials: "include", headers: { authorization: "Bearer " + session.docs_jwt } }).then(() => {
        const path = ($state.params.docs.startsWith("/") ? "" : "/") + $state.params.docs;
        window.location.href = `${DOCS_ROOT}${path}`;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$state.params.docs]);

  function setEntity(id: number) {
    Menu.setPermissionsAndEntity(new PermissionManager(session?.permissions), id);
    const state = Menu.getFirstRoute(id);

    // if there's no access to anything for single entity users, make sure the no access message appears
    if (state.params.noAccess && session?.entityList.length === 1) {
      setAccessWarning("You have no permissions granted.");
    } else {
      $state.go(state.route, state.params);
    }
  }

  useEffect(() => {
    if (session?.entityList.length === 0) {
      setAccessWarning("You have no entities assigned.");
    } else if (session?.entityList.length === 1 && !entityID) {
      setEntity(session?.entityList[0].ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.entityList]);

  // if there is only one entity, don't show the list, since the above useEffect will take care of the redirect
  if (session?.entityList.length === 1 || accessWarning) {
    return <Content fullWidth>{accessWarning ? <h3>{accessWarning} Please contact your administrator or account manager.</h3> : <></>}</Content>;
  }

  return (
    <Content fullWidth>
      <div className="company-list">
        {session?.entityList?.map((entity) => (
          <div className="company-row ng-scope inactive single all-inactive" key={entity.ID}>
            <div className="triangle" />
            <h3 className="company" onClick={() => setEntity(entity.ID)}>
              <span>{entity.name}</span>
            </h3>
          </div>
        ))}
      </div>
    </Content>
  );
};

export const AngularHome = react2angular(withReduxProvider(Home));
