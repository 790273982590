/**
 * Common/index.js
 * This is where all the services, resources, directives, etc, are included
 * @module common
 */

require("./jQuery.ui.dragslider");

export default angular.module("common", ["ui.sortable"]).name;

require("./cm-errors");

require("./animations/fade-in");
require("./animations/fade-out");
require("./animations/scroll-top");
require("./animations/slide-down");
require("./animations/slide-left");
require("./animations/slide-right");
require("./animations/slide-up");

require("./directives/cm-color-picker");
require("./directives/cm-image-preview");
require("./directives/cm-image-uploader");
require("./directives/code-mirror");
require("./directives/create-problem");
require("./directives/epi-checkbox");
require("./directives/epi-checkbox-switch");
require("./directives/epi-collapse-triangle");
require("./directives/epi-confirm");
require("./directives/epi-filter");
require("./directives/epi-filters-creator");
require("./directives/epi-menu");
require("./directives/epi-modal");
require("./directives/epi-paging");
require("./directives/epi-right");
require("./directives/epi-sliding-panel");
require("./directives/epi-spinner");
require("./directives/epi-validate");
require("./directives/fade-away");
require("./directives/fade-switch");
require("./directives/fine-uploader-button");
require("./directives/fine-uploader");
require("./directives/json-text");
require("./directives/loading-indicator");
require("./directives/multi-select");
require("./directives/ng-enter");
require("./directives/ng-multi-transclude");
require("./directives/nicescroll");
require("./directives/off-click");
require("./directives/progress-indicator");
require("./directives/redactor");
require("./directives/scroll-to");
require("./directives/select-element");
require("./directives/slide-away");
require("./directives/small-loading-indicator");

require("./filters/capitalize");
require("./filters/dateToISO");
require("./filters/numeral");
require("./filters/trusted");

require("./services/array-helper");
require("./services/collapser");
require("./services/color-helper");
require("./services/date-helper");
require("./services/date-range");
require("./services/error-logger");
require("./services/file-validator");
require("./services/google-geocoder");
require("./services/http-interceptor");
require("./services/keystroke-search");
require("./services/menu");
require("./services/session");
require("./services/string-helper");
require("./services/validator");

require("./providers/storage");
