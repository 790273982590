import React, { useMemo, useState } from "react";
import { ICollection } from "../../types/ICollection";
import { MapSelectionSummary } from "./MapSelectionSummary";
import { classNameMapper } from "../../utils/classNameMapper";
import { createPortal } from "react-dom";
import { Modal } from "../../components/Modal";
import { useGetMapActionsQuery } from "../../slices/apiSlice";
import { IMapAction, Interaction } from "../../types/IMapAction";
import { store } from "../../store";
import { setBackendActionInvocation } from "../../slices/pagesSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { Box, ClickAwayListener, Fade, Popper, Typography } from "@mui/material";
import { useAvailableReports } from "../../hooks/useAvailableReports";
import { IDefaultActions } from "./Map";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectedFeatures = { [key: string]: { [key: string]: any } };

interface IMapSelectionActionsBarProps {
  mapID: number;
  selectedFeatures: SelectedFeatures;
  defaultActions: IDefaultActions;
}

export const MapSelectionActionsBar: React.FC<IMapSelectionActionsBarProps & JSX.IntrinsicAttributes> = ({
  mapID,
  selectedFeatures,
  defaultActions,
}: IMapSelectionActionsBarProps) => {
  const [showSummary, setShowSummary] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [showReportPopover, setShowReportPopover] = useState(false);
  const [reportPopoverAnchor, setReportPopoverAnchor] = useState<null | HTMLElement>(null);
  const {
    map,
    availableCollections,
    backendActionInvocation: inProgressInvocation,
  } = useAppSelector((state) => state.pages.truterritory);
  const { reports: availableReports } = useAvailableReports();

  const { data: backendActions = [] } = useGetMapActionsQuery(mapID);
  const collections = useMemo(
    () =>
      map?.collectionIDs
        .map((id) => availableCollections.find((c) => c.ID == id))
        .filter((c): c is ICollection => !!c) || [],
    [map, availableCollections]
  );
  const selectedCollections = useMemo(
    () => Object.keys(selectedFeatures).map((layerName) => collections?.find((c) => c.tableName == layerName)),
    [selectedFeatures, collections]
  );
  const actionFilter = (a: IMapAction) =>
    a.enabled &&
    (a.scope?.includes(Interaction.SELECT) || !a.scope) &&
    (a.sourceID === null || !!selectedCollections.find((c) => c?.ID === a.sourceID));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const enabledBackendActions = useMemo(
    () => backendActions.filter(actionFilter),
    [backendActions, selectedCollections]
  );
  const selectionIDs = useMemo(
    () =>
      Object.keys(selectedFeatures).reduce(
        (accum, layerName) => ({ ...accum, [layerName]: Object.keys(selectedFeatures[layerName]) }),
        {}
      ),
    [selectedFeatures]
  );
  const ableToSummarize = collections.filter((c) => !!c.aggSql).length > 0;

  function toggleSummary() {
    setShowSummary(!showSummary);
  }

  function clickToDownload(event: React.MouseEvent<HTMLElement>) {
    setShowReportPopover(true);
    setReportPopoverAnchor(event.currentTarget);
  }

  /**
   * Begin working to perform an action at the user's request.
   * The MapBackendActions component will pick this up from the store.
   */
  function startBackendAction(action: IMapAction) {
    if (action?.ID) {
      store.dispatch(setBackendActionInvocation({ action, selection: selectionIDs }));
    }
  }

  return (
    <>
      <ul className="actions">
        <li>
          <a className="go-to" onClick={defaultActions.goTo}>
            Go To
          </a>
        </li>
        <li>
          <a className="filter-by" onClick={defaultActions.filterBy}>
            Filter By
          </a>
        </li>
        {ableToSummarize && (
          <li>
            <a className="summarize" onClick={toggleSummary}>
              {showSummary ? "Hide" : "Show"} Summary
            </a>
          </li>
        )}
        {!!availableReports.length && (
          <li>
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setShowReportPopover(false)}>
              <span>
                <a
                  className="download-report"
                  style={{ textDecoration: "line-through" }}
                  onClick={(e) => clickToDownload(e)}
                >
                  Download Report
                </a>
                <Popper open={showReportPopover} anchorEl={reportPopoverAnchor} placement="top-start" transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Box
                        sx={{
                          p: 1,
                          paddingBottom: 2,
                          width: 200,
                          bgcolor: "background.paper",
                          boxShadow: 4,
                          borderRadius: "3px",
                        }}
                      >
                        <h4>Reports have moved!</h4>
                        <Typography sx={{ fontSize: "1.3rem" }}>
                          <span>
                            To download a report, right-click on your selected features (highlighted on the map).
                          </span>
                        </Typography>
                      </Box>
                    </Fade>
                  )}
                </Popper>
              </span>
            </ClickAwayListener>
          </li>
        )}
        {enabledBackendActions.map((action) => (
          <li key={action.ID}>
            <a
              onClick={startBackendAction.bind(null, action)}
              className={`${classNameMapper({ loading: inProgressInvocation?.action.ID == action.ID })}`}
            >
              {action.label || action.defaultName}
            </a>
          </li>
        ))}
        <li>
          <a className="clear" onClick={defaultActions.clear}>
            Clear Selection
          </a>
        </li>
      </ul>
      {ableToSummarize &&
        showSummary &&
        createPortal(
          <MapSelectionSummary selectedFeatures={selectedFeatures} collections={collections} />,
          document.getElementById("map-holder")!
        )}

      <Modal isOpen={!!errorMsg} onClose={() => {}} id="truterritory-selection-error-modal">
        <div className="modal-body">
          <h4>Error</h4>
          <p>Could not perform action. {errorMsg}</p>
          <div className="controls">
            <button className="btn orange" type="button" onClick={setErrorMsg.bind(null, "")}>
              Close
            </button>
          </div>
          <div className="modal-footer"></div>
        </div>
      </Modal>
    </>
  );
};
