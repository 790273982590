/**
 * Data/index.js (handles all the routing for the data pages)
 */

export default angular.module("routes:data", []).config(function ($stateProvider) {
  $stateProvider
    .state("data", {
      url: "/data/:entityID",
      template: "<epi-data-router></epi-data-router>",
    })
    .state("data.create-edit", {
      url: "/:collectionID",
      template: "<epi-data-router></epi-data-router>",
    });
}).name;
