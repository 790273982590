import L from "leaflet";
import "../../../node_modules/leaflet-areaselect/src/leaflet-areaselect";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { react2angular } from "react2angular";
import { getService } from "react-in-angularjs";

import { ITruMap } from "../../types/ITruMap";
import { setIsSnippetToolOpen } from "../../slices/pagesSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { withReduxProvider } from "../../services/withReduxProvider";
import { MAPBOX_ACCESS_TOKEN, MAPBOX_STYLE_ID, MAPBOX_TILE_SIZE, MAPBOX_USER, TILE_URL } from "../../../app/configuration";

interface DownloadImageProps {
  truMap: ITruMap;
  markerId?: string;
  disabled?: boolean;
}

const DownloadImage: React.FC<DownloadImageProps & JSX.IntrinsicAttributes> = ({ truMap, markerId, disabled }) => {
  const dispatch = useAppDispatch();

  const [boundingBoxIsVisible, setBoundingBoxIsVisible] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 200, height: 200 });
  const [magnification, setMagnification] = useState(1);
  const [boundingBox, setBoundingBox] = useState<L.Layer>();

  const leafletMap = truMap.getLeafletMap();
  const [mapSize, _setMapSize] = useState(leafletMap.getSize());
  const [mapZoom, _setMapZoom] = useState(leafletMap.getZoom());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const magnifications = useMemo(createMagnifications, [mapZoom, dimensions]);

  // We hold onto references of our event listeners so that we can remove them by reference later.
  // (New functions are defined every time this component is rendered, so leaflet won't recognize
  // them in the future.)
  const { current: handleDragResize } = useRef(_handleDragResize);
  const { current: updateMapSize } = useRef(_updateMapSize);
  const { current: updateMapZoom } = useRef(_updateMapZoom);

  // Manage bounding box lifecycle.
  useEffect(() => {
    if (boundingBoxIsVisible) {
      dispatch(setIsSnippetToolOpen(true));
      createBoundingBox();
    } else {
      dispatch(setIsSnippetToolOpen(false));
      destroyBoundingBox();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundingBoxIsVisible]);

  function createBoundingBox() {
    // Create the Leaflet element.
    const width = 200,
      height = 200;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bb = new (L as any).AreaSelect({ width, height });
    bb.addTo(leafletMap);
    setBoundingBox(bb);
    setDimensions({ width, height });
    setMagnification(1);
    updateMapSize();
    updateMapZoom();

    // Bind the event listeners
    bb.on("changing", handleDragResize);
    leafletMap.on("resize", updateMapSize);
    leafletMap.on("zoom", updateMapZoom);
  }

  function destroyBoundingBox() {
    boundingBox?.off("change");
    boundingBox?.off("changing");
    boundingBox?.remove();
    leafletMap.off("resize", updateMapSize);
    leafletMap.off("zoom", updateMapZoom);

    // Getting into the weeds here, but the AreaSelect plugin does not provide `context` when
    // unregistering its "resize" listener, resulting in Leaflet ignoring the call. Here
    // we pass boundingBox as the third argument to truly remove the listener.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    leafletMap.off("resize", (boundingBox as any)?._onMapResize, boundingBox);
  }

  // Handle the box being resized.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function _handleDragResize(event: any) {
    const size = getBoxSize(event.target);
    setDimensions({ width: size.width, height: size.height });
  }

  // Handle the map (browser window) being resized.
  function _updateMapSize() {
    _setMapSize(leafletMap.getSize());
  }

  // Handle the map being zoomed in or out.
  function _updateMapZoom() {
    _setMapZoom(leafletMap.getZoom());
  }

  // Convert the box width and height into pixels.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getBoxSize(boundingBox?: any) {
    if (!boundingBox) return { width: 200, height: 200 };

    const bounds = boundingBox.getBounds();

    const ne = truMap.getLeafletMap().latLngToLayerPoint(bounds.getNorthEast());
    const sw = truMap.getLeafletMap().latLngToLayerPoint(bounds.getSouthWest());

    const width = ne.x - sw.x;
    const height = sw.y - ne.y;

    return { width, height };
  }

  // Create all the available magnification options.
  function createMagnifications(): { value: number; name: string }[] {
    // Loop through all valid zoom levels (1-20), translating them into a magnification factor relative to the current zoom.
    const newMags = [];
    for (let i = 1; i <= 20; i++) {
      const factor = zoomToMagnification(i);

      // If the dimensions are too big or too small, don't add it
      if (dimensions.width * factor >= 16384 || dimensions.height * factor >= 16384 || dimensions.width * factor <= 16 || dimensions.height * factor <= 16) continue;

      newMags.push({ value: factor, name: factor + "x" });
    }

    // If the current user-selected magnification factor isn't available anymore, set it to 1x (current zoom).
    if (newMags.find((factor) => factor.value === magnification) === undefined) setMagnification(1);

    return newMags;
  }

  // Open the link to download the image.
  function downloadImage() {
    if (!boundingBox) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bounds = (boundingBox as any).getBounds();
    const marker = markerId && truMap._layers[markerId] && (truMap._layers[markerId] as L.Marker).getLatLng();
    window.open(getImageDownloadUrl(magnificationToZoom(magnification), bounds.getSouthWest(), bounds.getNorthEast(), marker ? [marker.lng, marker.lat] : undefined));
  }

  // Handle the user entering new width or height as pixels.
  function changeSize(width: number, height: number) {
    if (!boundingBox) return;

    if (isNaN(width) || width < 0) {
      width = 0;
    }
    if (isNaN(height) || height < 0) {
      height = 0;
    }

    // Cannot exceed map dimensions.
    width = width <= mapSize.x ? width : mapSize.x;
    height = height <= mapSize.y ? height : mapSize.y;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (boundingBox as any).setDimensions({ width, height });
    setDimensions({ ...dimensions, width, height });
  }

  // Convert a magnification option to a zoom level relative to the current zoom.
  function magnificationToZoom(factor: number): number {
    return mapZoom + Math.log2(factor);
  }

  // Convert a zoom level to a magnification relative to the current zoom.
  function zoomToMagnification(zoom: number): number {
    return Math.pow(2, zoom - mapZoom);
  }
  const getImageDownloadUrl = function (zoom: number, sw: L.LatLng, ne: L.LatLng, marker?: L.LatLngExpression): string {
    const INSTANCE_ID = getService("INSTANCE_ID");
    const url = [TILE_URL, "tile", INSTANCE_ID, zoom, "snippet.png"],
      params = {
        user: MAPBOX_USER,
        styleID: MAPBOX_STYLE_ID,
        tileSize: MAPBOX_TILE_SIZE,
        highdpi: false,
        accessToken: MAPBOX_ACCESS_TOKEN,
        bbox: JSON.stringify([
          [sw.lng, sw.lat],
          [ne.lng, ne.lat],
        ]),
        marker: marker && JSON.stringify(marker),
      };

    return url.join("/") + "?" + $.param(params);
  };

  return (
    <>
      {boundingBoxIsVisible && dimensions ? (
        <div className="bounding-box-is-visible">
          <form
            className="selector"
            name="downloadImageForm"
            onSubmit={(e) => {
              e.preventDefault();
              downloadImage();
            }}
          >
            <div className="dimension">
              <label>Width:</label>
              <input type="number" name="width" value={dimensions.width} max={mapSize.x} min="1" onChange={(e) => changeSize(parseInt(e.target.value), dimensions.height)} />
              <span>px</span>
            </div>
            <div className="dimension">
              <label>Height:</label>
              <input type="number" name="height" value={dimensions.height} max={mapSize.y} min="1" onChange={(e) => changeSize(dimensions.width, parseInt(e.target.value))} />
              <span>px</span>
            </div>
            <div className="dimension" title="Magnification">
              <label>Mag:</label>
              <div className="select-holder">
                <select value={magnification} onChange={(e) => setMagnification(parseFloat(e.target.value))}>
                  {magnifications.map((factor, i) => (
                    <option key={`${factor.value}-${i}`} value={factor.value}>
                      {factor.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="controls">
              <button type="submit" className="btn">
                Download Image
              </button>
            </div>
          </form>
          <button
            className="btn done"
            onClick={() => {
              setBoundingBoxIsVisible(false);
            }}
          >
            Done
          </button>
        </div>
      ) : (
        <button
          className="btn download"
          onClick={() => {
            setBoundingBoxIsVisible(true);
          }}
          disabled={boundingBoxIsVisible || disabled}
        />
      )}
    </>
  );
};

export default DownloadImage;

DownloadImage.propTypes = {
  truMap: PropTypes.any.isRequired,
  markerId: PropTypes.string,
};

export const AngularDownloadImage = react2angular(withReduxProvider(DownloadImage), ["truMap", "markerId"]);
