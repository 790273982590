import * as CodeMirror from "codemirror";

CodeMirror.defineMode("cartocss", function (config, parserConfig) {
  var cartoOverlay = {
    token: function (stream) {
      let rgb = /\([0-9]{1,3},\s?[0-9]{1,3},\s?[0-9]{1,3}(,\s?(0\.[0-9]{1,3}|1))?\)/;
      let ch = stream.peek();

      // Match hex, but not css ids
      if (ch == "#" && stream.match(/#/, false)) {
        stream.eat(/#/);
        stream.match(/(?!.*\s*{)([0-9A-Fa-f]{1,6})/, true);
        if (stream.current().length > 1 || stream.eol() || stream.match(";", false)) return "atom color";
      }

      if (ch == "r" && stream.match(/rgb(a)?/, true)) {
        stream.match(rgb, true);
        return "atom color";
      }

      if (stream.match("setMarker(")) {
        stream.backUp(1);
        this.markerState = [stream.current()];
        return "atom marker";
      }

      if (ch == "(" && this.markerState) {
        stream.next();
        this.markerState.push("(");
        return null;
      }

      if (this.markerState && this.markerState.length == 2 && this.markerState.join("") == "setMarker(") {
        stream.match(/(.*)(?:\))/, true);
        stream.backUp(1);
        this.markerState = null;
        return "string markerUrl";
      }

      if (stream.next() == null) return null;
    },
  };
  return CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop || "css"), cartoOverlay);
});
