import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { Circle, Geometry, SelectionIDs } from "./MapBackendActions";
import { Point } from "geojson";
import { API_ROOT } from "../../../app/configuration";
import { Modal } from "../../components/Modal";
import { getPreflightMap, oxfordConcat, PreflightMap } from "../../data/map";
import { Field, Formik } from "formik";
import { setProgress, setReportInvocation } from "../../slices/pagesSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { store } from "../../store";
import { featureCollection } from "@turf/helpers";
import center from "@turf/center";
import { IFilter, IMap } from "../../types/IMap";
import { ICollection } from "../../types/ICollection";
import { getService } from "react-in-angularjs";
import { useRunReportMutation } from "../../slices/apiSlice";

// eslint-disable-next-line @typescript-eslint/no-require-imports
const snippetFilteredImage = require("../../../app/assets/snippet-selection-filtered.png");
// eslint-disable-next-line @typescript-eslint/no-require-imports
const snippetNotFilteredImage = require("../../../app/assets/snippet-selection-not-filtered.png");

export type ReportInvocation = {
  ID: number;
  type: "old" | "new";
  address?: string;
  marker?: number[];
  selection?: SelectionIDs;
  point?: Point;
  circle?: Circle;
};

export type RunReportRequest = {
  reportID: number;
  selection?: SelectionIDs;
  geometry?: Geometry;
  circle?: Circle;
  map: PreflightMap;
  address?: string;
  marker?: number[];
};

export type RunReportResponse = {
  taskID: string;
};

export type RunReportStatus = {
  steps: number;
  stepsCompleted: number;
  success?: boolean;
  errors?: string[];
  fileUUID?: string;
};

export const ReportDownloader: React.FC<JSX.IntrinsicAttributes> = () => {
  const dispatch = useAppDispatch();

  const { map, reportInvocation, availableCollections, progress } = useAppSelector((state) => state.pages.truterritory);
  const [runReport] = useRunReportMutation();
  const [askForFilters, setAskForFilters] = useState(false);
  const [applyFilters, setApplyFilters] = useState<boolean | null>(null);
  const [runReportResponse, setRunReportResponse] = useState<RunReportResponse | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState("");

  // Translate the layer names into propert Collection names.
  const collectionNames = useMemo(() => {
    if (!reportInvocation?.selection) {
      return [];
    }

    return Object.keys(reportInvocation?.selection)
      .map((layerName) => availableCollections?.find((c) => c.tableName == layerName))
      .map((c) => c?.name || "");
  }, [reportInvocation, availableCollections]);

  // If something has put a report invocation on state, ask for settings OR download it right away if nothing is required.
  useEffect(() => {
    if (!map || !reportInvocation || progress) return;

    // If the report will be run on a selection, and we haven't asked about filters, do it now.
    if (reportInvocation.selection && applyFilters === null) {
      if (askForFilters === false) {
        setAskForFilters(true);
      }

      return;
    }

    // Otherwise start the download.
    // If it's a request for an old-style report, just download it synchronously.
    if (reportInvocation.type === "old") {
      dispatch(setProgress({ text: "", value: 0, determinate: false }));
      downloadOldReport(reportInvocation, map, availableCollections, !!applyFilters).then(() => {
        cancelOrCleanupDownload();
      });
      return;
    }

    // If it's a request for a new style report, trigger its generation and then poll until it's ready to download.
    dispatch(setProgress({ text: "", value: 0, determinate: true }));
    // Unset the invocation so it never gets used again.
    dispatch(setReportInvocation(undefined));
    runReport({
      reportID: reportInvocation.ID,
      selection: reportInvocation.selection,
      geometry: reportInvocation.point,
      circle: reportInvocation.circle,
      map: makeReportMapConfig(reportInvocation, map, availableCollections, !!applyFilters),
      address: reportInvocation.address,
      marker: reportInvocation.marker,
    })
      .unwrap()
      .then((resp) => {
        setRunReportResponse(resp);
      })
      .catch(() => {
        cancelOrCleanupDownload();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportInvocation, askForFilters, applyFilters, progress]);

  // Long poll for report updates
  useEffect(() => {
    if (!map || !runReportResponse) {
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const trackStatus = async () => {
      while (!signal.aborted) {
        for await (const pendingReports of getPendingReports(map.entityID, signal)) {
          // There could be multiple reports pending at the same time, but for now
          // just grab the one we triggered last.
          const status = pendingReports[runReportResponse.taskID];
          if (status) {
            // On error, show a popup and close the progress indicator.
            if (status.success === false && status.errors?.length) {
              setErrorMsg(status.errors[0]);
              cancelOrCleanupDownload();
            } else {
              dispatch(
                setProgress({ text: "", value: (status.stepsCompleted / status.steps) * 100, determinate: true })
              );
            }

            // If the report is ready, trigger the download.
            if (status.success && status.fileUUID) {
              await downloadNewReport(status.fileUUID, map.entityID);
              cancelOrCleanupDownload();
            }
          }
        }
      }
    };

    trackStatus();

    return () => {
      dispatch(setProgress(undefined));
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, runReportResponse]);

  function cancelOrCleanupDownload() {
    dispatch(setReportInvocation(undefined));
    setAskForFilters(false);
    setApplyFilters(null);
    setRunReportResponse(undefined);
    dispatch(setProgress(undefined));
  }

  return (
    <>
      <Modal isOpen={askForFilters} onClose={() => {}} id="truterritory-report-modal">
        <div className="modal-header">
          <h4>Download Report</h4>
        </div>
        <div className="modal-body">
          <Formik
            initialValues={{ applyFilters: true }}
            onSubmit={(values) => {
              setApplyFilters(values.applyFilters);
              setAskForFilters(false);
            }}
          >
            {({ setFieldValue, handleSubmit }) => (
              <form className="form-horizontal" onSubmit={handleSubmit}>
                <p>
                  You have selected shapes in {oxfordConcat(collectionNames, true)}. How would you like to display the
                  shapes in {oxfordConcat(collectionNames, true)} which you didn&apos;t select?
                </p>
                <div
                  className="image-buttons"
                  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                  onChange={(e: any) => setFieldValue("applyFilters", e.target.value === "true")}
                >
                  <label>
                    <img src={snippetFilteredImage} alt="selection" />
                    <Field type="radio" name="applyFilters" value={true} />
                    <span>Filter out other shapes in selected layers</span>
                  </label>
                  <label>
                    <img src={snippetNotFilteredImage} alt="selection" />
                    <Field type="radio" name="applyFilters" value={false} />
                    <span>Highlight my selection, but show all shapes</span>
                  </label>
                </div>
                <div className="controls">
                  <button className="btn light" type="submit">
                    Download Report
                  </button>
                  <button className="btn plain" type="button" onClick={cancelOrCleanupDownload}>
                    Cancel
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="modal-footer"></div>
      </Modal>

      <Modal isOpen={!!errorMsg} onClose={() => {}} id="truterritory-action-error-modal">
        <div className="modal-body">
          <h4>Error</h4>
          <p>Could not perform action. {errorMsg}</p>
          <div className="controls">
            <button className="btn orange" type="button" onClick={setErrorMsg.bind(null, "")}>
              Close
            </button>
          </div>
          <div className="modal-footer"></div>
        </div>
      </Modal>
    </>
  );
};

/**
 * Download an old-style report. Generates and downloads in a single request.
 */
async function downloadOldReport(
  invocation: ReportInvocation,
  map: IMap,
  availableCollections: ICollection[],
  applyFilters: boolean
): Promise<string> {
  if (invocation.type !== "old") {
    return "Incorrect invocation for downloading old-style report";
  }

  if (!invocation.point && !invocation.selection) {
    return "A point or selection is required for generating an old-style report";
  }

  // Old-style reports need a point, so if we've got a selection derive its centroid as the point.
  const INSTANCE_ID = getService("INSTANCE_ID");
  const point = invocation.point || getSelectionCentroid();
  const url = `${API_ROOT}/mapping/reports/${invocation.ID}/${INSTANCE_ID}/${point.coordinates[1]}/${point.coordinates[0]}?entityID=${map.entityID}`;
  const body = {
    // Include map preflight config only if there's an active selection.
    map: invocation.selection ? makeReportMapConfig(invocation, map, availableCollections, applyFilters) : undefined,
    address: invocation.address,
    format: "PDF",
    selection: invocation.selection,
  };

  return download(url, body);
}

/**
 * Download a new-style report. Requires that the request to generate a report has been completed separately.
 */
async function downloadNewReport(uuid: string, entityID: number): Promise<string> {
  const url = `${API_ROOT}/reporting/download/${uuid}?entityID=${entityID}`;
  return download(url);
}

/**
 * Download the blob at the given URL and open it in a new tab.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function download(url: string, body?: any): Promise<string> {
  try {
    // Download the bytestream and load it into a Blob.
    const resp = await fetch(url, {
      method: body ? "POST" : "GET",
      headers: body ? { "Content-Type": "application/json" } : undefined,
      body: body ? JSON.stringify(body) : undefined,
      credentials: "include",
    });

    if (resp.status != 200) {
      const data = await resp.json();
      if (data.status && data.status.message) {
        return data.status.message;
      }

      return resp.statusText;
    }

    // Create an object URL for the blob, make an anchor element pointing to it, and "click" on the element.
    const href = URL.createObjectURL(await resp.blob());
    const a = document.createElement("a");
    a.href = href;
    a.download = "report.pdf";

    // Clean up on click
    const cleanup = () => {
      setTimeout(() => {
        URL.revokeObjectURL(href);
        removeEventListener("click", cleanup);
      }, 200);
    };

    a.addEventListener("click", cleanup, false);
    a.click();

    return "";
  } catch (error: unknown) {
    console.log("returning error", error);
    return `${error}`;
  }
}

/**
 * Make a generator to return previous and new pending reports.
 */
async function* getPendingReports(
  entityID: number,
  signal: AbortSignal
): AsyncGenerator<{ [key: string]: RunReportStatus }, void, unknown> {
  try {
    const url = `${API_ROOT}/reporting/report-runs?entityID=${entityID}`;
    const decoder = new TextDecoder();
    const response = await fetch(url, { credentials: "include", signal });
    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error("Unexpected response in report status");
    }

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        console.log("all done!");
        return;
      }

      const decoded = decoder.decode(value);
      const messages = decoded.split("__MSG_SEP__").filter((msg) => msg !== "");
      for (const msg of messages) {
        yield JSON.parse(msg);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.name !== "AbortError") {
      throw e;
    }
  }
}

/**
 * This function is necessary only to support old-style reports which require a lat/lon in the URL.
 */
function getSelectionCentroid(): Point {
  // Get the actual features (as GeoJSON) so we can work with their geometry.
  // By getting the selectedFeatures like this instead of using the selection passed into the report invocation,
  // we're ignoring the possibility that some features may have been "selected" via a click without being present
  // in the vector layers. That's OK for old-style map reports because the click handler for an old-style reports
  // will ensure we get a Point already and don't have to run this code path. This should only run for intentional
  // selections via the vector layers.
  const selection = store.getState().pages.truterritory.selectedFeatures;
  if (!selection) {
    throw new Error("No features selected");
  }

  const features = Object.values(selection).flatMap((layer) => Object.values(layer));
  return center(featureCollection(features)).geometry;
}

/**
 * For both old and new reports, we need a map preflight object so that images of the map can be included in the report.
 * We optionally filters the layers so that only what the user wants to see show up.
 */
function makeReportMapConfig(
  invocation: ReportInvocation,
  map: IMap,
  availableCollections: ICollection[],
  applyFilters: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const preflight = getPreflightMap(map, availableCollections);

  // Set filters.
  if (applyFilters && invocation.selection) {
    for (const layerName in invocation.selection) {
      const collection = availableCollections?.find((c) => c.tableName == layerName);
      if (!collection) continue;
      const filter: IFilter = {
        operator: "IN",
        column: collection.idColName ?? "",
        value: Object.keys(invocation.selection[layerName]),
      };

      // To make this work predictably, we have to first remove any "IN" filter(s) on idColName.
      const preflightCollection = preflight.collections.find((c) => c.ID == collection.ID);
      if (!preflightCollection) continue;
      const baseFilters: IFilter[] = (preflightCollection.filters || []).filter(
        (f) => !(f.operator == "IN" && f.column == collection.idColName)
      );

      // We add to existing filters for this collection (implicit AND), if present.
      preflightCollection.filters = [...baseFilters, filter];
    }
  }

  return preflight;
}
