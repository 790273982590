import { ReactNode } from "react";

export const TopNavbarSecondary = ({ children, title, noSecondaryMenu }: { title: string; noSecondaryMenu?: boolean; children?: ReactNode }) => {
  return (
    <nav id="top-nav-secondary" className={`navbar navbar-default ${noSecondaryMenu ? "no-secondary-menu" : ""}`}>
      <h3>{title}</h3>
      {children}
    </nav>
  );
};

export const NavbarUl = ({ right, children, id }: { right?: boolean; id?: string; children?: ReactNode }) => {
  return (
    <ul className={`navbar-nav ${right ? "navbar-right" : ""}`} id={id}>
      {children}
    </ul>
  );
};

export const NavbarLi = ({ pullLeft, children, className }: { pullLeft?: boolean; className?: string; children?: ReactNode }) => {
  return <li className={`${pullLeft ? "pull-left" : ""} ${className ?? ""}`}>{children}</li>;
};
