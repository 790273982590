import { Moment } from "moment";
import { IMapAction } from "./IMapAction";
import { ICollection, ITheme, IThemeLegend } from "./ICollection";

export interface IMap {
  ID: number;
  name: string;
  entityID: number;
  workspaceID: number;
  parentID?: number | null; // null, not just undefined, so that we can explicitly clear its persisted value when needed.
  UUID: string;
  password: null | string;
  dateEnabled: boolean;
  compEnabled: boolean;
  sliderType: number;
  sliderInterval: number;
  minDate?: Date;
  maxDate?: Date;
  compInterval?: string | null;
  reportsEnabled: boolean;
  reportsCollectionID: null;
  createdAt: Date | null;
  updatedAt: Date | null;
  sorder: number;
  collectionIDs: number[];
  visible: { [key: string]: boolean };
  selectable: { [key: string]: boolean };
  editable: { [key: string]: boolean };
  themes: { [key: string]: number };
  filters: { [key: string]: IFilter[] };
  labels: { [key: string]: null | string };
  passwordRequired: boolean;
  children?: IMap[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reports: any[];
  allowCollaboration: boolean;
  isPublic?: boolean;
  aggregates?: Record<string, Record<string, string>>;

  // calculated values
  actions?: IMapAction[];
  maxDateRange?: { minDate?: Moment; maxDate?: Moment };
  /* dateRange holds the dates that the user has set, which will be sent back to the server in the preflight request */
  dateRange?: { minDate?: Moment; maxDate?: Moment };
  collections?: ICollection[];
  legend?: IMapLegend[];
  annotations?: GeoJSON.FeatureCollection;
}

export interface IMapWithCollections extends IMap {
  collections: ICollection[];
}

export interface IMapLegend {
  entries: IThemeLegend[];
  collectionID: number;
  theme?: ITheme;
  name?: string;
}

export type ICreateMap = Omit<IMap, "ID">;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isICreateMap(object: any): object is ICreateMap {
  return !("ID" in object) || !object.ID; // the seoncd check is for null or undefined
}

export type IUpdateMap = Partial<IMap> & Pick<IMap, "ID">;

export interface Filters {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any[];
}

export interface Labels {
  [key: string]: string | null;
}

export type Themes = object;

export interface Visible {
  [key: string]: boolean;
}

export interface IFilter {
  column: string;
  operator: string;
  value: string | string[] | number | boolean;
}

export interface IMapAnnotationsResponse {
  timestamp: string;
  deleted: GeoJSON.FeatureCollection;
  updated: GeoJSON.FeatureCollection;
}
