import { useRef, useState } from "react";
import { Button, IButtonProps } from "../design/Button";
import { useOutsideClick } from "../hooks/useOutsideClick";

interface IButtonGroupDropdownProps extends IButtonProps {
  id?: string;
  buttonText?: string;
}

export const ButtonGroupDropdown = ({ children, buttonText, id, ...buttonProps }: IButtonGroupDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div className={`btn-group dropdown${isOpen ? " open" : ""}`} id={id} ref={dropdownRef}>
      <Button {...buttonProps}>{buttonText}</Button>
      <Button className="dropdown-toggle" onClick={() => setIsOpen((v) => !v)} />
      <ul className="dropdown-menu" onClick={() => setIsOpen(false)}>
        {children}
      </ul>
    </div>
  );
};
