const { ContextMenu } = require("../../../../../src/pages/TruTerritory/ContextMenu");
import { createRoot } from "react-dom/client";

L.ContextMenu = L.Layer.extend({
  initialize: function (latlng, truMap, customOptions = [], searchText = {}, clickedOnSearchPin = false) {
    this._latlng = latlng;
    this.truMap = truMap;
    this._customOptions = customOptions;
    this._searchText = searchText;
    this._clickedOnSearchPin = !!clickedOnSearchPin;
  },

  onAdd: function (map) {
    this._map = map;

    // create a DOM element, render a React component in it, and put it into one of the map panes
    this._el = L.DomUtil.create("div", "context-menu leaflet-zoom-hide", map.getPanes().overlayPane);
    this._el.id = "leaflet-context-menu";
    const root = createRoot(this._el);
    root.render(<ContextMenu latlng={this._latlng} truMap={this.truMap} customOptions={this._customOptions} searchText={this._searchText} clickedOnSearchPin={this._clickedOnSearchPin} />);
    map.getPanes().overlayPane.appendChild(this._el);

    // add a viewreset event listener for updating layer's position, do the latter
    map.on("viewreset", this._reset, this);
    this._reset();
  },

  onRemove: function (map) {
    // remove layer's DOM elements and listeners
    map.getPanes().overlayPane.removeChild(this._el);
    map.off("viewreset", this._reset, this);
  },

  _reset: function () {
    // update layer's position
    var pos = this._map.latLngToLayerPoint(this._latlng);
    L.DomUtil.setPosition(this._el, pos);
  },
});
