export default angular.module("modules:mapping", ["modules"]).name;

require("./collection");
require("./feature");
require("./map-reports");
require("./template");
require("./theme");
require("./workspace");

require("./epi-map/L.contextMenu");
require("./epi-map/label-layer");
require("./epi-map/truMap");
