/* eslint-disable @typescript-eslint/no-require-imports */
import React, { useEffect, useState } from "react";
import { store } from "../../store";
import * as geojson from "geojson";
import { useGetMapActionPreflightQuery, useInvokeMapActionMutation } from "../../slices/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { setBackendActionInvocation, setProgress } from "../../slices/pagesSlice";
import { IMapAction } from "../../types/IMapAction";
import { Modal } from "../../components/Modal";
import { Field, Formik } from "formik";
import { Button } from "../../design/Button";
import { useAppSelector } from "../../hooks/useAppSelector";
import { shouldShowAsEnum } from "../../data/collection";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const optionImages: { [key: string]: any } = {
  "handle-overlap.allow": require("../../../app/assets/snippet-selection-overlap-allow.png"),
  "handle-overlap.deselect": require("../../../app/assets/snippet-selection-overlap-deselect.png"),
  "handle-overlap.replace": require("../../../app/assets/snippet-selection-overlap-replace.png"),
};

export type SelectionIDs = { [key: string]: string[] };
export type Geometry = geojson.Geometry;
export type Circle = {
  x: number;
  y: number;
  radius: number;
  srid: number;
};
export type BackendActionInvocation = {
  selection?: SelectionIDs;
  geometry?: Geometry;
  circle?: Circle;
  action: IMapAction;
  input?: Input;
};

export type ActionPreflightRequest = {
  actionID: number;
  selection?: SelectionIDs;
  geometry?: Geometry;
  circle?: Circle;
};

export type ActionPreflightResponse = {
  required: {
    fields?: Field[];
    decisions?: Decision[];
    confirmationMessage?: string | undefined;
  };
  messages: string[];
  errors: string[];
};

type Field = {
  name: string;
  column: string;
  type: string;
  allowedValues?: string[];
};

type Decision = {
  id: string;
  description: string;
  useImages: boolean;
  options: DecisionOption[];
};

type DecisionOption = {
  id: string;
  text: string;
  confirmationMessage: string | undefined;
};

type Answers = { [key: string]: string | number };

type Input = {
  fields?: Answers;
  decisions?: Answers;
};

export type ActionInvokeRequest = {
  actionID: number;
  selection?: SelectionIDs;
  geometry?: Geometry;
  circle?: Circle;
  input: Input;
};

export type ActionInvokeResponse = {
  completed: boolean;
  isError: boolean;
  message: string;
};

type Props = {
  // Function to clear the selection on action completion.
  clear: () => void;
  // Function to reload the map on action completion.
  reload?: () => void;
};

export const MapBackendActions: React.FC<JSX.IntrinsicAttributes & Props> = ({ clear, reload }: Props) => {
  const invocation = useAppSelector((state) => state.pages.truterritory.backendActionInvocation);
  const [errorMsg, setErrorMsg] = useState("");
  const [confirmationMessages, setConfirmationMessages] = useState<string[]>([]);

  // Make the preflight call if an action has been invoked.
  const buildPreflightRequest = () =>
    invocation?.action.ID && {
      actionID: invocation.action.ID,
      selection: invocation.selection,
      geometry: invocation.geometry,
      circle: invocation.circle,
    };
  const {
    data: preflightResponseRaw,
    isFetching: preflightInProgress,
    error,
  } = useGetMapActionPreflightQuery(buildPreflightRequest() || skipToken);
  if (!errorMsg && error) {
    setErrorMsg(
      "status" in error
        ? "error" in error
          ? error.error
          : JSON.stringify(error.data)
        : error.message || "Unexpected error"
    );
    store.dispatch(setBackendActionInvocation(undefined));
  }
  // Never use a cached preflight while another one is in-progress or after an action is completed/canceled.
  const preflightResponse =
    preflightResponseRaw && !preflightInProgress && invocation ? preflightResponseRaw : undefined;

  // Handle error coming from preflight.
  if (!errorMsg && preflightResponse?.errors.length) {
    setErrorMsg(preflightResponse.errors[0]);
    store.dispatch(setBackendActionInvocation(undefined));
  }

  // If preflight requires a top-level confirmation, show the message to the user.
  if (!confirmationMessages?.length && preflightResponse?.required.confirmationMessage) {
    setConfirmationMessages([preflightResponse.required.confirmationMessage]);
  }

  // If preflight does not require any fields, set submitted fields to an empty answer
  // so that we know to proceed with invoking the action.
  const [submittedFields, setSubmittedFields] = useState<Answers>();
  if (!submittedFields && preflightResponse?.required.fields?.length === 0) {
    setSubmittedFields({});
  }

  // If preflight does not require any decisions, set submitted decisions to an empty answer
  // so that we know to proceed with invoking the action.
  const [submittedDecisions, setSubmittedDecisions] = useState<Answers>();
  if (!submittedDecisions && preflightResponse?.required.decisions?.length === 0) {
    setSubmittedDecisions({});
  }

  // If preflight does not require decisions and does not require top-level confirmation, mark this as confirmed.
  const [confirmed, setConfirmed] = useState(false);
  if (
    !confirmed &&
    !preflightResponse?.required.confirmationMessage &&
    preflightResponse?.required.decisions?.length === 0
  ) {
    setConfirmed(true);
  }

  // If fields and decisions are both set, and we've received confirmation from the user, complete the input for the invocation.
  const [backendActionInput, setBackendActionInput] = useState<Input>();
  if (!backendActionInput && submittedFields && submittedDecisions && confirmed) {
    setBackendActionInput({ fields: submittedFields, decisions: submittedDecisions });
  }

  // If input is set and there's no error from preflight, invoke the action.
  const [invokeBackendAction] = useInvokeMapActionMutation();

  useEffect(() => {
    (async () => {
      if (invocation?.action.ID && preflightResponse && !preflightResponse?.errors.length) {
        if (backendActionInput != undefined) {
          try {
            store.dispatch(setProgress({ text: "", value: 0, determinate: false }));

            const req = {
              actionID: invocation.action.ID,
              selection: invocation.selection,
              geometry: invocation.geometry,
              circle: invocation.circle,
              input: backendActionInput || {},
            };
            await invokeBackendAction(req).unwrap();
            clear();
            if (invocation.action.reloadOnCompletion && reload) {
              reload();
            }
          } catch (e) {
            console.log("Error invoking action", e); // The global error handler will already display an error modal.
          } finally {
            store.dispatch(setProgress(undefined));
            cancelAction();
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invocation, preflightResponse, backendActionInput]);

  function cancelAction() {
    store.dispatch(setBackendActionInvocation(undefined));
    setSubmittedFields(undefined);
    setSubmittedDecisions(undefined);
    setConfirmationMessages([]);
    setConfirmed(false);
    // Apparently unsetting all these in the right order matters. If backendActionsInput is unset
    // before the fields it depends on (i.e., moving it up in this list), it can magically be re-set
    // to the old value.
    setBackendActionInput(undefined);
  }

  // A single confirmation will clear the first of any required confirmations.
  function confirm() {
    setConfirmationMessages(confirmationMessages?.slice(1));
    setConfirmed(!confirmationMessages?.slice(1).length);
  }

  // Get the default value for the given field. Will prepulate from values set somewhere upstream if available.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getDefaultValue(field: Field): any {
    if (
      invocation?.input?.fields &&
      field.column in invocation.input.fields &&
      invocation.input.fields[field.column] !== undefined
    ) {
      return invocation.input.fields[field.column];
    }

    if (field.type == "text") {
      return "";
    }

    return 0;
  }

  return (
    <>
      <Modal isOpen={!!errorMsg} onClose={() => {}} id="truterritory-action-error-modal">
        <div className="modal-body">
          <h4>Error</h4>
          <p>Could not perform action. {errorMsg}</p>
          <div className="controls">
            <button className="btn orange" type="button" onClick={setErrorMsg.bind(null, "")}>
              Close
            </button>
          </div>
          <div className="modal-footer"></div>
        </div>
      </Modal>

      <Modal
        isOpen={!!confirmationMessages && confirmationMessages.length > 0}
        onClose={() => {}}
        id="truterritory-action-error-modal"
      >
        <div className="modal-body">
          <h4>Confirmation Required</h4>
          <p>{confirmationMessages && confirmationMessages[0]}</p>
          <div className="controls">
            <button className="btn light" type="button" onClick={confirm}>
              Yes, continue
            </button>
            <button className="btn plain" type="button" onClick={cancelAction}>
              Cancel
            </button>
          </div>
          <div className="modal-footer"></div>
        </div>
      </Modal>

      {/* Show the modal for accepting user input of required fields if an action is in
      progress, the preflight has been sent, fields are required, and fields are not
      yet submitted */}
      <Modal
        isOpen={!!invocation?.action && !!preflightResponse?.required.fields?.length && !submittedFields}
        onClose={() => {}}
        id="truterritory-selection-action-input-modal"
      >
        <Formik
          initialValues={
            preflightResponse?.required.fields?.reduce(
              (accum, field) => ({ ...accum, [field.column]: getDefaultValue(field) }),
              {}
            ) || {}
          }
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setSubmittedFields(values);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <>
              <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="modal-body col-md-12">
                  <h4>Input Required</h4>
                  <p>This action requires additional input.</p>

                  {preflightResponse?.required.fields?.map((field) => (
                    <div className="form-group" key={field.column}>
                      <label className="control-label col-md-3" htmlFor={`action-required-${field.column}`}>
                        {field.name}
                      </label>
                      <div className="col-md-6">
                        {(field.type == "text" && shouldShowAsEnum(field) && (
                          <div className="select-holder">
                            <Field
                              name={field.column}
                              as="select"
                              id={`action-required-${field.column}`}
                              style={{ marginTop: 0 }}
                            >
                              <option value="">Select...</option>
                              {field.allowedValues
                                ?.filter((v) => typeof v == "string")
                                .map((val, i) => (
                                  <option value={val} key={i}>
                                    {val}
                                  </option>
                                ))}
                            </Field>
                          </div>
                        )) ||
                          (field.type == "text" && (
                            <Field
                              name={field.column}
                              type="text"
                              id={`action-required-${field.column}`}
                              className="form-control"
                            />
                          )) || (
                            <Field
                              name={field.column}
                              type="number"
                              // inputmode="numeric"
                              pattern={field.type == "decimal" ? "d+.d+" : "d+"}
                              step={field.type == "decimal" ? 0.01 : 1}
                              id={`action-required-${field.column}`}
                              className="form-control"
                            />
                          )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <div className="controls">
                    {isSubmitting ? (
                      <Button type="submit" variant="light-blue" disabled>
                        Running...
                      </Button>
                    ) : (
                      <Button type="submit" variant="light-blue">
                        Continue
                      </Button>
                    )}
                    <Button variant="plain" onClick={cancelAction}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </Modal>

      {/* Show the modal for accepting user input of required decisions if an action is in
  in progress, the preflight has been sent, decisions are required, decisions are not
  yet submitted, fields have been submitted (so that this modal always shows second,
  not at the same time as fields) AND decisions have not been submitted (so that this
  isn't open at the same time as confirmation, which comes next). */}
      <Modal
        isOpen={
          !!invocation?.action &&
          !!preflightResponse?.required.decisions?.length &&
          !!submittedFields &&
          !confirmationMessages?.length &&
          !backendActionInput
        }
        onClose={() => {}}
        id="truterritory-selection-action-input-modal"
      >
        <Formik
          initialValues={
            preflightResponse?.required.decisions?.reduce((accum, decision) => ({ ...accum, [decision.id]: "" }), {}) ||
            {}
          }
          onSubmit={(values: Answers, { setSubmitting }) => {
            setSubmitting(true);
            setSubmittedDecisions(values);

            // If a chosen option requires confirmation, get its message.
            const decisions = preflightResponse?.required.decisions || [];
            const messages = Object.keys(values)
              .map((decisionID) => decisions.find((d) => d.id == decisionID))
              .map((decision) => decision?.options.find((o) => o.id == values[decision.id]))
              .map((option) => option?.confirmationMessage)
              .filter((msg) => !!msg);
            setConfirmationMessages(messages as string[]); // Yay for TS

            // If there are no messages, go ahead and mark this as confirmed.
            if (!messages.length) {
              setConfirmed(true);
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <>
              <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="modal-body col-md-12">
                  <h4>Decision Required</h4>

                  {preflightResponse?.required.decisions?.map((decision, index) => (
                    <div key={index}>
                      <p>{decision.description}</p>
                      {(decision.useImages && (
                        <div className="image-buttons">
                          {decision.options.map((option) => (
                            <label key={option.id}>
                              <img src={optionImages[`${decision.id}.${option.id}`]} />
                              <Field
                                name={`${decision.id}`}
                                id={`decisions.${decision.id}.${option.id}`}
                                type="radio"
                                value={option.id}
                                className=""
                              />
                              <span>{option.text}</span>
                            </label>
                          ))}
                        </div>
                      )) || (
                        <div className="decision-options-radios">
                          {decision.options.map((option) => (
                            <div key={option.id} className="decision-option">
                              <Field
                                name={`${decision.id}`}
                                id={`decisions.${decision.id}.${option.id}`}
                                type="radio"
                                value={option.id}
                                className=""
                              />
                              <label htmlFor={`decisions.${decision.id}.${option.id}`}>{option.text}</label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <div className="controls">
                    {isSubmitting ? (
                      <Button type="submit" variant="light-blue" disabled>
                        Running...
                      </Button>
                    ) : (
                      <Button type="submit" variant="light-blue">
                        Run
                      </Button>
                    )}
                    <Button variant="plain" onClick={cancelAction}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};
