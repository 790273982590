import * as Yup from "yup";
import { ICollection, ITheme, IThemeWithID } from "../types/ICollection";
import { IMap } from "../types/IMap";
import { getService } from "react-in-angularjs";
import { CollectionType } from "../types/CollectionTypes";

/**
 * Gets the collection theme either from the collection or gets the default theme
 * @param  {object}  map              the map object
 * @param  {boolean} [preferMapLabel=true] whether to prefer the map label over the theme label
 * @return {object}                   the theme
 */
export const findTheme = function (collection: ICollection, map: IMap, preferMapLabel = true): IThemeWithID | ITheme {
  let theme = collection.themes.find((t) => t.ID === map.themes[collection.ID]); //_.find(this.themes, { ID: map.themes[this.ID] });

  // If a theme isn't set on the map, grab the collection's first in the map's workspace
  if (!theme && collection.themes) theme = collection.themes.find((t) => t.workspaceID == map.workspaceID);

  // still nothing? use defaults
  if (!theme) theme = getService("Theme").getDefaultTheme(collection, map.workspaceID, "Default");

  // Copy the theme, so the original isn't modified
  theme = JSON.parse(JSON.stringify(theme));

  // If there's a label set at the map level,
  // change the label, and return the modified theme
  if (typeof map.labels[collection.ID] != "undefined" && preferMapLabel) theme!.label = map.labels[collection.ID];

  return theme!;
};

export const validationSchema = (collections: ICollection[], existingCollection?: Partial<ICollection>) =>
  Yup.object({
    name: Yup.string()
      .required("Please enter a name for this collection")
      .test("unique", "Sorry, this name is already being used or will result in a naming collision in the database.", (value?: string) => {
        if (!value) return true;

        // if we're editing, we can keep the same name
        if (value === existingCollection?.name) return true;

        const makeSlug = (str: string) => str.toLowerCase().replace(/[\s\-]+/g, "_").replace(/[^\w\s]/g, "");
        return (collections ?? [])?.every(({ name }) => makeSlug(name) !== makeSlug(value));
      }),
    srid: Yup.mixed().when("type", {
      is: CollectionType.Writable,
      then: Yup.number().required("Please enter an SRID"),
    }),
    file: Yup.mixed().when("type", {
      is: CollectionType.Master && !existingCollection?.ID,
      then: Yup.mixed().required("Please select a file"),
    }),
    sql: Yup.string()
      .when("type", {
        is: (val: CollectionType) => [CollectionType.Transformation, CollectionType.Dynamic].includes(val),
        then: Yup.string().required("Please enter a SQL query"),
      })
      .when("type", {
        is: (val: CollectionType) => ![CollectionType.Transformation, CollectionType.Dynamic].includes(val),
        then: Yup.string().notRequired().nullable(),
      }),
    properties: Yup.array().when("type", {
      is: CollectionType.Writable,
      then: Yup.array().of(
        Yup.object().shape({
          hidden: Yup.boolean(),
          protected: Yup.boolean(),
          required: Yup.boolean(),
          name: Yup.string().required("Please enter a name for this property"),
          type: Yup.string().required("Please select a type for this property"),
          column: Yup.string(),
        })
      ),
    }),
  });

export function shouldShowAsEnum(prop: { allowedValues?: string[]}): boolean {
  return Array.isArray(prop.allowedValues) && prop.allowedValues.filter((v) => typeof v === "string").length > 0;
}