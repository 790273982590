import { ReactNode, useMemo, useRef, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";

import { ICollection, ITheme, IThemeWithID } from "../../../types/ICollection";
import { classNameMapper } from "../../../utils/classNameMapper";
import { IHasMapAccess } from "../../../../app/routes/mapping/services/has-map-access";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { SortableLi } from "../../../components/Sortable/SortableLi";
import { Checkbox } from "../../../design/Checkbox";
import { IconFilter } from "../../../resources/icons/IconFilter";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { findTheme } from "../../../data/collection";

export const CollectionLi = ({
  isOpen,
  toggleOpen,
  collection,
  isVisible,

  isPublicPage,
  hasAccess,
  handleVisibleToggle,
  handleRemoveCollection,
  editTheme,
  cloneTheme,
  setTheme,
  addTheme,
  setLabel,
  onSelect,
}: {
  isOpen: boolean;
  toggleOpen: () => void;
  collection: ICollection;
  isVisible: boolean;
  isPublicPage?: boolean;
  hasAccess: IHasMapAccess;
  handleVisibleToggle: (ID: ICollection["ID"]) => void;
  handleRemoveCollection: (ID: ICollection["ID"]) => void;
  editTheme: (ID: ICollection["ID"], theme: ITheme) => void;
  cloneTheme: (ID: ICollection["ID"], theme: ITheme) => void;
  setTheme: (ID: ICollection["ID"], theme: IThemeWithID) => void;
  addTheme: (ID: ICollection["ID"]) => void;
  setLabel: (ID: ICollection["ID"], label: string | null) => void;
  onSelect: (ID: ICollection) => void;
}) => {
  const { map, workspaceID } = useAppSelector((state) => state.pages.truterritory);
  const theme = map && findTheme(collection, map, false);
  const label = map?.labels && collection.ID in map?.labels ? map?.labels[collection.ID] : theme?.label;
  const labelName = useMemo(() => collection.properties.find((prop) => prop.column === label)?.name || label, [collection, label]);

  return (
    <SortableLi
      className={`collection collection-${collection.ID} ` + classNameMapper({ visible: isVisible })}
      key={`collection-${collection.ID}`}
      id={collection.ID}
      useSeparateDragHandle
    >
      {!isPublicPage && hasAccess("update") && <DragHandle id={collection.ID} />}
      <div className="name" onClick={toggleOpen}>
        {collection.name}
      </div>
      {!isOpen && (
        <div className="label" onClick={toggleOpen}>
          {label ? "Label: " + labelName : "(no label)"}
        </div>
      )}
      <Checkbox name={`visible${collection.ID}`} id={`visible${collection.ID}`} onChange={() => handleVisibleToggle(collection.ID)} checked={isVisible} />
      {!isPublicPage && (
        <div className="collection-controls">
          {(map?.filters ?? {})[collection.ID]?.length > 0 && (
            <button title={"Collection has active filters. Click to open filters panel"} className="btn icon-btn filter active" onClick={() => onSelect(collection)}>
              <IconFilter />
            </button>
          )}
          <button className="btn arrow-right" onClick={() => onSelect(collection)} title="Click to open filters panel"></button>
          {hasAccess("delete") && <button className="btn del" onClick={() => handleRemoveCollection(collection.ID)} />}
        </div>
      )}
      <div className={["collapsible", isOpen ? "opened" : "closed"].join(" ")}>
        <div className="controls">
          {hasAccess("update") && (
            <Dropdown
              className="theme-selector"
              label={
                <>
                  <div className="dropdown-toggle">Theme: {theme?.name || "(no name)"}</div>
                  {!isPublicPage && hasAccess("update") && theme && (
                    <>
                      <button className="btn edit" onClick={() => editTheme(collection.ID, theme)}></button>
                      <button className="btn copy" onClick={() => cloneTheme(collection.ID, theme)}></button>
                    </>
                  )}
                </>
              }
            >
              {collection.themes
                .filter((t) => t.workspaceID == workspaceID)
                .map((t) => (
                  <li className={`theme ${classNameMapper({ selected: t.ID === theme?.ID })}`} key={`theme-${t.ID}`} onClick={() => setTheme(collection.ID, t)}>
                    {t.name}
                  </li>
                ))}
              {!isPublicPage && hasAccess("create") && (
                <li className="add-new" onClick={() => addTheme(collection.ID)}>
                  <span>Add New Theme</span>
                  <button className="btn add"></button>
                </li>
              )}
            </Dropdown>
          )}

          <Dropdown className="label-selector" label={<div className="dropdown-toggle"> Label: {labelName || "(no label)"}</div>}>
            <div className="option" onClick={() => setLabel(collection.ID, null)}>
              (no label)
            </div>
            {collection.properties.map((prop) => (
              <div
                className={`option ${classNameMapper({ "theme-label": theme?.label === prop.column })}`}
                key={`label-${prop.column}`}
                onClick={() => setLabel(collection.ID, prop.column)}
              >
                {prop.name}
              </div>
            ))}
          </Dropdown>
        </div>
      </div>
    </SortableLi>
  );
};

const Dropdown = ({ label, children, className }: { label: ReactNode; children: ReactNode; className?: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div className={`${className} ${classNameMapper({ open: isOpen })}`} onClick={() => setIsOpen((o) => !o)} ref={dropdownRef}>
      {label}
      {isOpen && (
        <ul className="dropdown" onClick={() => setTimeout(() => setIsOpen(false))}>
          {children}
        </ul>
      )}
    </div>
  );
};

const DragHandle = ({ id }: { id: number }) => {
  const { listeners, attributes } = useSortable({ id });
  return <div className="draggable ui-sortable-handle" title="Drag here to reorder" {...listeners} {...attributes} />;
};
