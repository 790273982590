import { react2angular } from "react2angular";
import PropTypes from "prop-types";
import { getService } from "react-in-angularjs";
import { useMemo, useState } from "react";

import { IMap } from "../../types/IMap";
import { IWorkspace } from "../../types/IWorkspace";
import { DropdownSelector } from "../../components/DropdownSelector";
import { classNameMapper } from "../../utils/classNameMapper";
import { IHasMapAccess } from "../../../app/routes/mapping/services/has-map-access";
import { ConfirmButton } from "../../components/ConfirmButton";
import { ICollection } from "../../types/ICollection";
import { Checkbox } from "../../design/Checkbox";
import { useAppSelector } from "../../hooks/useAppSelector";
import { withReduxProvider } from "../../services/withReduxProvider";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import { UserSelector } from "./UserSelector";
import {
  useCreateWorkspaceMutation,
  useGetCollectionsQuery,
  useGetWorkspaceQuery,
  useGetWorkspacesWithMapsQuery,
  useRemoveCollectionFromWorkspaceMutation,
  useRemoveUserFromWorkspaceMutation,
  useUpdateWorkspaceMutation,
} from "../../slices/apiSlice";
import { setMap, setMapDirty, removeCollection as removeCollectionInStore } from "../../slices/pagesSlice";
import { Gravatar } from "../../utils/gravatar";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { findTheme } from "../../data/collection";
import { Modal } from "../../components/Modal";
import { Button } from "../../design/Button";
import { skipToken } from "@reduxjs/toolkit/query";

interface ITruTerritoryLayersProps {
  switchWorkspace: (ID: number) => void;
  addMap: (mapID: number) => void;
  deleteMap: (map: IMap) => void;
}

export const TruTerritoryLayers: React.FC<ITruTerritoryLayersProps & JSX.IntrinsicAttributes> = ({ switchWorkspace, addMap, deleteMap }: ITruTerritoryLayersProps) => {
  const MappingModals = getService("MappingModals");
  const hasAccess: IHasMapAccess = getService("hasMapAccess");
  const Session = getService("Session");

  const dispatch = useAppDispatch();

  const { workspaceID, map } = useAppSelector((state) => state.pages.truterritory);

  const { data: workspaces } = useGetWorkspacesWithMapsQuery();
  const { data: workspace } = useGetWorkspaceQuery(workspaceID ? { ID: workspaceID, with: "collections,users,maps" } : skipToken);

  const [makeCreateWorkspaceRequest] = useCreateWorkspaceMutation();
  const [removeUserFromWorkspace] = useRemoveUserFromWorkspaceMutation();
  const [removeCollectionFromWorkspace] = useRemoveCollectionFromWorkspaceMutation();
  const [updateWorkspace] = useUpdateWorkspaceMutation();

  const [collectionSearch, setCollectionSearch] = useState("");
  const [limitToWorkspace, setLimitToWorkspace] = useState(false);
  const debouncedCollectionSearch = useDebouncedValue(collectionSearch);

  // these are used when removing the last user from a workspace
  const [lastUserCheck, setLastUserCheck] = useState(false);
  const [lastUserCheckWorkspace, setLastUserCheckWorkspace] = useState<IWorkspace>();

  const { data: allCollections } = useGetCollectionsQuery({ with: ["themes", "themes.rules"] });

  const openWorkspaceModal = MappingModals.openSaveWorkspace;

  const filteredCollections = useMemo(
    () =>
      ((limitToWorkspace ? workspace?.collections : allCollections) ?? []).filter(
        (c) => !!c.geometryType && c.name.toLowerCase().includes(debouncedCollectionSearch.toLowerCase())
      ),
    [workspace?.collections, allCollections, debouncedCollectionSearch, limitToWorkspace]
  );

  const isCollectionActive = function (collectionID: ICollection["ID"]) {
    return map?.collectionIDs.indexOf(collectionID) != -1;
  };

  const workspaceWithAppendedNames: (IWorkspace & { image?: string })[] = useMemo(
    () =>
      (workspaces ?? []).map((w) => {
        if (w.userID !== Session.get("ID")) {
          const user = w.users?.find((u) => u.ID === w.userID);
          if (user) {
            return { ...w, image: Gravatar(user.emailSum, 20), name: `${w.name} (${user?.fname} ${user?.lname}'s)` };
          }
        }

        return w;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspaces]
  );

  // Make a JSON replacer that both allows you to specify a list of fields to use, and keeps numerically indexed keys. Used to keep fields like `collectionIDs`, `themes` (on maps), etc. Currently only used on maps
  const makeJsonReplacer = (fields:string[]) => {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (key:any, value:any)=> {

      // Check if key is one of the specified fields
      if (fields.includes(key)) {
        return value;
      }

      // Check if key is a numeric string
      if (!isNaN(key)) {
        return value;
      }

      // Otherwise, exclude the key
      return undefined;
    }
  }

  // if these aren't set, return, they should be set by the time this component is next rendered
  if (!workspace || !map) return <></>;

  const removeWorkspace = async (w: IWorkspace) => {
    if (w.users.length === 1 && w.users[0].ID === Session.get("ID") && !lastUserCheck) {
      setLastUserCheck(true);
      setLastUserCheckWorkspace(w);
      return;
    } else {
      setLastUserCheck(false);
      setLastUserCheckWorkspace(undefined);
    }

    // make the actual request (this is post-modal confirmation)
    await removeUserFromWorkspace({ ID: w.ID, userID: Session.get("ID") });

    // if the current workspace is the one being removed, set the default workspace as selected
    if (w.ID === workspace.ID) {
      const defaultWorkspace = workspaceWithAppendedNames.find((w) => w.default);
      if (defaultWorkspace) {
        switchWorkspace(defaultWorkspace.ID);

        // this shouldn't ever happen (since there is always a default workspace), but just to cover else cases, it's here
      } else {
        switchWorkspace(workspaceWithAppendedNames[0]?.ID);
      }
    }
  };

  const removeCollectionFromMap = (ID: ICollection["ID"]) => {
    dispatch(removeCollectionInStore(ID));
    dispatch(setMapDirty(true));
  };

  const addCollection = (c: ICollection) => {
    const collection = { ...(workspace.collections.find((col) => col.ID === c.ID) || c) };
    collection.themes = (collection.themes && collection.themes.filter((t) => t.workspaceID === map.workspaceID)) || [];

    // Add the collection to the map and set necessary data
    const newMap = {
      ...map,
      collectionIDs: [...map.collectionIDs, collection.ID],
      visible: { ...map.visible, [collection.ID]: true },
    };

    const theme = findTheme(collection, map);
    if (theme?.ID) {
      newMap.themes[collection.ID] = theme.ID;
      newMap.labels[collection.ID] = theme.label;
    }

    dispatch(setMap(newMap));
    dispatch(setMapDirty(true));
  };

  const createWorkspace = async ({ name }: { name: IWorkspace["name"] }) => {
    const userID = Session.get("ID");
    const workspace = { name, userID: userID, userIDs: [userID] };
    const newWorkspace = await makeCreateWorkspaceRequest(workspace).unwrap();
    switchWorkspace(newWorkspace.ID);
  };

  const removeCollection = async (ID: ICollection["ID"]) => {
    removeCollectionFromWorkspace({ ID: workspace.ID, collectionID: ID });
  };

  const toggleSharing = async () => {
    updateWorkspace({ ID: workspace.ID, name: workspace.name, sharingEnabled: !workspace.sharingEnabled });
  };

  return (
    <div id="truterritory-workspace-module">
      {lastUserCheckWorkspace && (
        <Modal isOpen={lastUserCheck} onClose={() => setLastUserCheck(false)}>
          <div className="modal-body">
            <p>You are the last user in this workspace. Removing yourself from this workspace will also delete the workspace. Are you sure you want to do this?</p>
          </div>
          <div className="modal-footer">
            <div className="controls">
              <Button onClick={() => removeWorkspace(lastUserCheckWorkspace)}>Confirm</Button>
              <Button onClick={() => setLastUserCheck(false)} variant="plain">
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <DropdownSelector
        selection={workspace as IWorkspace} // omit collections
        items={workspaceWithAppendedNames}
        onClick={(w) => {
          switchWorkspace(w.ID);
          setCollectionSearch("");
          setLimitToWorkspace(false);
        }}
        onDelete={removeWorkspace}
        maxWidth="170%"
        minWidth="85%"
        restricted="default"
        openWorkspaceModal={openWorkspaceModal}
        createWorkspace={createWorkspace}
      />
      <div className="workspaces">
        <h4>Maps in &apos;{workspace.name}&apos;</h4>
        {!workspace.maps?.length && <h4 className="empty">There are no maps in this workspace</h4>}

        <ul className="map-list">
          {workspace.maps?.map((m) => (
            <li className={classNameMapper({ active: m.ID === map.ID }, "map-drop")} key={m.ID} onClick={() => addMap(m.ID)} title={JSON.stringify(m, makeJsonReplacer(['ID', 'name', 'sorder', 'allowCollaboration', 'parentID', 'collectionIDs', 'themes', 'workspaceID', 'createdAt', 'updatedAt']), 2)}>
              <div className="text">{m.name}</div>
              {hasAccess("delete") && <ConfirmButton type="del" text={`Are you sure you want to delete '${m.name}'?`} yes="Delete" callback={() => deleteMap(m)} />}
            </li>
          ))}
        </ul>

        <p className="info">You can click a map to view in order to get the data you need. </p>
      </div>
      <div className="collections">
        <h4>Collections</h4>
        <div className="input-search">
          <input className="search" type="text" placeholder="Search:" value={collectionSearch} onChange={({ target }) => setCollectionSearch(target.value)} />
        </div>
        <Checkbox name={`limitToWorkspace`} id={`limitToWorksace`} onChange={() => setLimitToWorkspace(!limitToWorkspace)} checked={limitToWorkspace} label="Limit to Workspace" />

        {filteredCollections.length <= 0 && limitToWorkspace && <h4 className="empty">There are no collections in this workspace</h4>}
        <ul className="collection-list">
          {filteredCollections.map((c) => (
            <li
              className={classNameMapper({ active: isCollectionActive(c.ID), disabled: !hasAccess("update") }, "collection collection-drop")}
              key={c.ID}
              onClick={() => (isCollectionActive(c.ID) ? removeCollectionFromMap(c.ID) : addCollection(c))}
              title={JSON.stringify(c, ['ID', 'name', 'type', 'idColName','tableName', 'geometryType', 'srid', 'noun','refreshEnabled', 'themes', 'createdAt', 'updatedAt'], 2)}
            >
              <div className={`text ${c.geometryType}`}>{c.name}</div>
              {limitToWorkspace && hasAccess("delete") && (
                <ConfirmButton type="del" text={`Are you sure you want to remove '${c.name}' from the current workspace?`} yes="Remove" callback={() => removeCollection(c.ID)} />
              )}
            </li>
          ))}
        </ul>
        <p className="info">You can click on any collection from this section to see the result on the map.</p>
      </div>

      {!workspace.default && <UserSelector users={workspace.users} />}

      <div className="options">
        <Checkbox name={`sharingEnabled`} id={`sharingEnabled`} checked={workspace.sharingEnabled} label="Enable sharing via public links" onChange={toggleSharing} />
      </div>
      {workspace.default && <h4 className="empty share-default">To share your workspace, first create and name a new one</h4>}
    </div>
  );
};

TruTerritoryLayers.propTypes = {
  addMap: PropTypes.func.isRequired,
};

export const AngularTruTerritoryLayers = react2angular(withReduxProvider(TruTerritoryLayers), Object.keys(TruTerritoryLayers.propTypes) as (keyof ITruTerritoryLayersProps)[], [
  "$scope",
]);
