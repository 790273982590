import React, { useEffect } from "react";
import { react2angular } from "react2angular";

import { useGetFaqsQuery } from "../slices/apiSlice";
import { updateGlobalState } from "../slices/appSlice";
import { Content } from "../design/layout/Content";
import { Menu } from "../components/Menu";
import { withReduxProvider } from "../services/withReduxProvider";
import { useAppDispatch } from "../hooks/useAppDispatch";

export const Faqs = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      updateGlobalState({
        title: "Frequently Asked Questions",
        h1: "",
        activeSection: "",
        activePage: "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: faqs, isLoading } = useGetFaqsQuery();

  return (
    <>
      <Menu />
      <Content className="faq">
        <h2 id="faq-top">Frequently Asked Questions</h2>
        {isLoading && <h4 className="empty">Loading...</h4>}
        <nav>
          <ul>
            {faqs?.map((faq) => (
              <li key={`faq-header-${faq.slug}`}>
                <a href={`#${faq.slug}`}>{faq.question}</a>
              </li>
            ))}
          </ul>
          <dl>
            {faqs?.map((faq) => (
              <React.Fragment key={`faq-${faq.slug}`}>
                <dt id={faq.slug}>{faq.question}</dt>
                <dd dangerouslySetInnerHTML={{ __html: faq.answer }}></dd>
                <a href="#faq-top">Top</a>
              </React.Fragment>
            ))}
          </dl>
        </nav>
      </Content>
    </>
  );
};

Faqs.propTypes = {};

export const AngularFaqs = react2angular(withReduxProvider(Faqs));
