import { TextMarker } from "codemirror";

// regex to match hex, rgb and rgba colors
const regex = /#([a-fA-F0-9]{3,8})|rgba?\((\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})(?:,\s?(\d{1,}|0\.\d{1,}))?\)/g;

export const ColorPicker = (editor: CodeMirror.Editor) => {
  const wrapper = $(editor.getWrapperElement());

  createColorBoxes();

  ["keyup", "paste", "cut"].forEach((event) => {
    wrapper.on(event, createColorBoxes);
  });

  // If there are color pickers around, delete them!
  wrapper.on("mouseup", function (event: JQuery.MouseUpEvent) {
    if (!$(event.target).parents(".sp-container").length) {
      wrapper.children(".sp-container").remove();
    }
  });

  function createColorBoxes() {
    editor.operation(() => {
      for (let i = 0; i < editor.lineCount(); i++) {
        const lineHandle = editor.getLineHandle(i);
        const matches = lineHandle.text.match(regex);
        const markers = (lineHandle as unknown as { markedSpans: { marker: TextMarker }[] }).markedSpans?.map((span) => span.marker) ?? [];

        if (markers.length) {
          markers.forEach((marker) => marker.clear());
        }
        if (!matches) continue;

        const color = matches[0];
        const start = lineHandle.text.indexOf(color);
        editor.findMarksAt({ line: i, ch: start }).forEach((mark) => mark.clear());

        const box = $('<span class="color-box box"></span>');
        box.css("background", color);
        box.on("click", onColorBoxClick.bind(null, i));

        editor.setBookmark({ line: i, ch: start }, { widget: box[0], insertLeft: true });
      }
    });
  }

  function onColorBoxClick(lineNumber: number, event: JQuery.ClickEvent) {
    const lineHandle = editor.getLineHandle(lineNumber);
    const matches = lineHandle.text.match(regex);
    const color = matches ? matches[0] : "";

    const startingRange = editor.getLine(lineNumber).indexOf(":") + 2;

    const picker = $(`<input type="text" value="${color}" />`);

    wrapper.prepend(picker);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (picker as any).spectrum({
      flat: true,
      showButtons: false,
      showAlpha: true,
      showInitial: true,
      clickoutFiresChange: true,
      preferredFormat: "hex",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      move: function (color: any) {
        const line = editor.getLineHandle(lineNumber).text;
        editor.replaceRange(color?.toString() + ";", { line: lineNumber, ch: startingRange }, { line: lineNumber, ch: line.length });
        createColorBoxes();
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const container = (picker as any).spectrum("container");
    container.css("position", "absolute");
    container.css($(event.target).position());
  }
};
