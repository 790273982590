/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { ICollection } from "../../types/ICollection";
import { IMapAction, Interaction } from "../../types/IMapAction";
import { AreaType, IMapActionType } from "../../types/IMapActionType";
import { Field, FieldArray, FormikErrors } from "formik";
import { classNameMapper } from "../../utils/classNameMapper";
import Select from "@mui/material/Select";
import { FormControl, MenuItem } from "@mui/material";

interface IMapFormActionsProps {
  collections: ICollection[];
  actions: IMapAction[];
  actionTypes: IMapActionType[];
  errors: any;
  touched: any;
  handleChange: (e: any) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<any>>;
}

export const MapFormActions: React.FC<IMapFormActionsProps & JSX.IntrinsicAttributes> = ({
  collections,
  actions,
  actionTypes,
  errors,
  touched,
  handleChange,
  setFieldValue,
}: IMapFormActionsProps) => {
  function validateRequired(value: any): string | undefined {
    return typeof value == "undefined" ||
      value === "" ||
      value === null ||
      (value instanceof Array && value.length == 0)
      ? "Required"
      : undefined;
  }

  function errorForActionProp(index: number, prop: string): string | undefined {
    return (
      touched.actions &&
      touched.actions[index] &&
      touched.actions[index][prop] &&
      errors.actions &&
      errors.actions[index] &&
      errors.actions[index][prop]
    );
  }

  return (
    <>
      <FieldArray name="actions">
        {({ remove, push }) => (
          <div className="actions">
            <table>
              <thead>
                <tr>
                  <th>Enabled</th>
                  <th>Action</th>
                  <th>Source</th>
                  <th>Target</th>
                  <th>Label</th>
                  <th>Verb</th>
                  <th>Scope</th>
                  <th>Refresh Downstream</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {actions.map((action, index) => {
                  const actionType = actionTypes.find((actionType) => actionType.ID == action.type);
                  return (
                    <tr key={index}>
                      <td>
                        <Field
                          type="checkbox"
                          name={`actions.${index}.enabled`}
                          id={`map-action-${index}-enabled`}
                          className={classNameMapper({ _checked: action.enabled })}
                        />
                        <label htmlFor={`map-action-${index}-enabled`} className="checkbox"></label>
                      </td>
                      <td>
                        <div className="select-holder">
                          <select value={action.type} name={`actions.${index}.type`} onChange={handleChange}>
                            <option value="">(none)</option>
                            {actionTypes.map((actionType) => (
                              <option
                                key={actionType.ID}
                                value={actionType.ID}
                                disabled={!actionType.enabled}
                                title={actionType.disabledReason}
                              >
                                {actionType.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                      <td>
                        {(actionType?.requiresSource && (
                          <div
                            className={classNameMapper({
                              "select-holder": true,
                              invalid: !!errorForActionProp(index, "sourceID"),
                            })}
                          >
                            <Field as="select" name={`actions.${index}.sourceID`} validate={validateRequired}>
                              <option value="">(select source)</option>
                              {collections
                                .filter((c) => !!actionType.allowedSources.find((id) => id == c.ID))
                                .map((collection) => (
                                  <option key={collection.ID} value={collection.ID}>
                                    {collection.name}
                                  </option>
                                ))}
                            </Field>
                          </div>
                        )) || <span>none</span>}
                      </td>
                      <td>
                        {(actionType?.requiresTarget && (
                          <div
                            className={classNameMapper({
                              "select-holder": true,
                              invalid: !!errorForActionProp(index, "targetID"),
                            })}
                          >
                            <Field as="select" name={`actions.${index}.targetID`} validate={validateRequired}>
                              <option value="">(select target)</option>
                              {collections
                                .filter((c) => !!actionType.allowedTargets.find((id) => id == c.ID))
                                .map((collection) => (
                                  <option key={collection.ID} value={collection.ID}>
                                    {collection.name}
                                  </option>
                                ))}
                            </Field>
                          </div>
                        )) || <span>none</span>}
                      </td>
                      <td>
                        <Field
                          type="text"
                          name={`actions.${index}.label`}
                          placeholder="Custom Label"
                          validate={validateRequired}
                          className={errorForActionProp(index, "label") && "invalid"}
                        />
                      </td>
                      <td>
                        <Field type="text" name={`actions.${index}.verb`} placeholder="Verb" />
                      </td>
                      <td>
                        <ActionScopeSelector
                          action={action}
                          actionType={actionType}
                          index={index}
                          handleChange={handleChange}
                          setFieldValue={(field, value) => setFieldValue(`actions.${index}.${field}`, value)}
                        />
                      </td>
                      <td>
                        {(actionType?.performsWriteOnSource || actionType?.performsWriteOnTarget) && (
                          <>
                            <Field
                              type="checkbox"
                              name={`actions.${index}.refreshDownstream`}
                              id={`map-action-${index}-refreshDownstream`}
                              className={classNameMapper({ _checked: action.refreshDownstream })}
                            />
                            <label htmlFor={`map-action-${index}-refreshDownstream`} className="checkbox"></label>
                          </>
                        )}
                      </td>
                      <td>
                        <button className="btn del" onClick={(e) => remove(index) && e.preventDefault()}></button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="add-action" onClick={() => push({ type: "", label: "", verb: "", enabled: true })}>
              <button className="btn add" onClick={(e) => e.preventDefault()}></button>
              <span>Add Action</span>
            </div>
          </div>
        )}
      </FieldArray>
    </>
  );
};

type ActionScopeSelectorProps = {
  action: IMapAction;
  actionType?: IMapActionType;
  index: number;
  handleChange: (e: any) => void;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<any>>;
};

const ActionScopeSelector: React.FC<ActionScopeSelectorProps & JSX.IntrinsicAttributes> = ({
  action,
  actionType,
  index,
  handleChange,
  setFieldValue,
}: ActionScopeSelectorProps) => {
  // If the action type supports selection, add SELECTION to scope options.
  const options: Interaction[] = [];
  if (actionType?.supportedAreaTypes?.includes(AreaType.SELECTION)) {
    options.push(Interaction.SELECT);
  }

  // If the action type supports geometry or circle, add CLICK to scope options.
  if (
    actionType?.supportedAreaTypes?.includes(AreaType.GEOMETRY) ||
    actionType?.supportedAreaTypes?.includes(AreaType.CIRCLE)
  ) {
    options.push(Interaction.CLICK);
  }

  // If no options are available, say so.
  if (options.length == 0) {
    return <span>N/A</span>;
  }

  // If only one option is available, make sure it gets set for this action and don't present a choice to the user.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (options.length == 1 && !equals(action.scope || [], options)) {
      setFieldValue("scope", options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  if (options.length == 1) {
    return <span>{titleCase(options[0])}</span>;
  }

  function titleCase(str: string): string {
    return str.charAt(0) + str.slice(1).toLocaleLowerCase();
  }

  function equals(arr1: any[], arr2: any[]): boolean {
    if (arr1.length != arr2.length) {
      return false;
    }

    const sorted1 = [...arr1].sort();
    const sorted2 = [...arr2].sort();
    for (let i = 0; i < sorted1.length; i++) {
      if (sorted1[i] != sorted2[i]) return false;
    }

    return true;
  }

  return (
    <>
      <FormControl sx={{ m: 1, width: 100 }}>
        <Select
          id={`map-action-${index}-scope`}
          name={`actions.${index}.scope`}
          multiple
          displayEmpty={true}
          value={action.scope || []}
          onChange={handleChange}
          MenuProps={{
            style: { zIndex: 10000 },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {titleCase(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
