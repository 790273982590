import angular from "angular";
import { AngularDataCollections } from "./Data/DataCollections";
import { AngularDataNavbar } from "./Data/DataNavbar";
import { AngularInfiniteConsole } from "./Data/InfiniteConsole";
import { AngularFaqs } from "./Faqs";
import { AngularHome } from "./Home";
import { AngularMyProfile } from "./MyProfile";
import { AngularTruTerritoryNavbar } from "./TruTerritory/TruTerritoryNavbar/TruTerritoryNavbar";
import { AngularTruTerritorySide } from "./TruTerritory/Side/TruTerritorySide";
import { AngularTruTerritoryBookmarks } from "./TruTerritory/TruTerritoryBookmarks";
import { AngularEpiMap } from "./TruTerritory/Map";
import { AngularToggleTriangle } from "./TruTerritory/ToggleTriangle";
import { AngularTruTerritoryLayers } from "./TruTerritory/TruTerritoryLayers";
import { AngularTruTerritoryTheme } from "./TruTerritory/TruTerritoryTheme";
import { AngularDownloadImage } from "./TruTerritory/DownloadImage";
import { AngularTruterritory } from "./TruTerritory/TruTerritory";
import { AngularCollectionsCreateEdit } from "./Data/CollectionsCreateEdit";
import { AngularDataRouter } from "./Data/DataRouter";

export default angular
  .module("pages", [])
  .component("epiMyProfilePage", AngularMyProfile)
  .component("epiFaqs", AngularFaqs)
  .component("epiHome", AngularHome)
  .component("epiDataNavbar", AngularDataNavbar)
  .component("epiDataRouter", AngularDataRouter)
  .component("epiInfiniteConsole", AngularInfiniteConsole)
  .component("epiDataCollections", AngularDataCollections)
  .component("epiCollectionsCreateEdit", AngularCollectionsCreateEdit)
  .component("epiTruterritoryNavbar", AngularTruTerritoryNavbar)
  .component("epiTruterritorySide", AngularTruTerritorySide)
  .component("epiTruTerritoryBookmarks", AngularTruTerritoryBookmarks)
  .component("epiMapV2", AngularEpiMap)
  .component("epiTruterritoryToggleTriangle", AngularToggleTriangle)
  .component("epiTruterritoryThemeV2", AngularTruTerritoryTheme)
  .component("epiTruterritoryDownloadImage", AngularDownloadImage)
  .component("epiTruterritoryLayersV2", AngularTruTerritoryLayers)
  .component("epiTruterritoryV2", AngularTruterritory).name;
