import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { react2angular } from "react2angular";
import { Menu } from "../../components/Menu";
import { Provider } from "react-redux";
import { store } from "../../store";
import { DataCollections } from "./DataCollections";
import { DataNavbar } from "./DataNavbar";
import "./router.css";
import { InfiniteConsole } from "./InfiniteConsole";

export const DataRouter = () => {
  const router = createBrowserRouter([
    {
      path: "/data/:entityId",
      element: (
        <>
          <Menu />
          <div id="page-wrap">
            <div id="content-wrap">
              <DataNavbar />
              <div id="content" className="has-navbar no-secondary-menu row">
                <div id="data">
                  <DataCollections />

                  <InfiniteConsole />
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ]);

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export const AngularDataRouter = react2angular(DataRouter);
