/**
 * @name cmErrors
 * @memberOf common
 * @ngdoc directive
 * @requires common.CodeMirror
 * @description Provides an API to show error on a Code Mirror instance
 */

export const Errors = (editor, errors, element) => {
  const $element = $(element);
  editor.setOption("gutters", ["CodeMirror-lint-markers", "CodeMirror-linenumbers"]);

  setTimeout(function () {
    editor.clearGutter("CodeMirror-lint-markers");
    editor.eachLine(function (line) {
      editor.removeLineClass(line.lineNo(), "background", "error-background");
      if (errors && errors[line.lineNo() + 1]) {
        editor.setGutterMarker(line, "CodeMirror-lint-markers", createMarker(errors[line.lineNo() + 1]));
        editor.addLineClass(line, "background", "error-background");
      }
    });
  });

  function createMarker(text) {
    let elem = $('<span class="CodeMirror-lint-marker-warning"></span>');

    let tooltip = $(`<div class="error-tooltip">${text}</div>`);
    tooltip.css("position", "absolute");

    let { top } = $element.parent().offset();

    let timeout;

    elem.on("mouseover", function () {
      $element.parent().parent().append(tooltip);
    });

    elem.on("mousemove", function (event) {
      tooltip.css({ top: event.pageY - top, left: 23 });
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        tooltip.remove();
      }, 10000);
    });

    elem.on("mouseout", function () {
      tooltip.remove();
    });

    return elem.get(0);
  }
};
