import { useEffect, useState } from "react";
import { isFetchBaseQueryError, useTestCollectionSqlMutation } from "../../slices/apiSlice";
import { classNameMapper } from "../../utils/classNameMapper";
import { CodeMirrorEditor } from "../../components/CodeMirrorEditor/CodeMirrorEditor";

export const SqlEditor = ({
  sql,
  onChange,
  onBlur: handleBlur,
}: {
  sql: string;
  onChange: (sql: string | undefined) => void;
  onBlur: (sql: string | undefined) => void;
}) => {
  const [isTesting, setIsTesting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [explain, setExplain] = useState<string[]>();
  const [showSuccess, setShowSuccess] = useState(false);
  const [errors, setErrorMsg] = useState<string | null>(null);
  const [explainTimeout, setExplainTimeout] = useState<NodeJS.Timeout>();

  const [testSql] = useTestCollectionSqlMutation();

  // Cleanup timeout on unmount
  useEffect(() => () => explainTimeout && clearTimeout(explainTimeout), [explainTimeout]);

  const handleTest = async () => {
    setIsTesting(true);
    try {
      const result = await testSql(sql).unwrap();
      setShowSuccess(true);
      setIsSuccess(true);
      setExplainTimeout(
        setTimeout(() => {
          setShowSuccess(false);
          setExplainTimeout(undefined);
        }, 5000)
      );
      setErrorMsg("");
      setExplain(result.explain);
      setIsTesting(false);
    } catch (e) {
      if (isFetchBaseQueryError(e)) {
        setErrorMsg(((e.data ?? {}) as { status?: { message?: string } }).status?.message ?? "An error occurred");
      } else {
        setErrorMsg("An error occurred");
      }
      setIsTesting(false);
    }
  };

  return (
    <div className="sql-editor">
      <CodeMirrorEditor
        value={sql}
        onChange={(e) => {
          onChange(e);
          setShowSuccess(false);
          setIsSuccess(false);
        }}
        onBlur={handleBlur}
        mode="text/x-pgsql"
        errors={{}}
      />
      <span className={classNameMapper({ disabled: isTesting || isSuccess }, "btn")} onClick={handleTest}>
        Explain SQL
      </span>
      {showSuccess && <span className="text-success">SQL passed!</span>}
      <div className="text-danger errors">{errors}</div>
      {explain && (
        <div className="sql-explain">
          <textarea disabled value={explain.join("\n")}></textarea>
        </div>
      )}
    </div>
  );
};
