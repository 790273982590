export default angular.module("views", []).run([
  "$templateCache",
  function ($templateCache) {
    $templateCache.put("/NoEntitySelected.html", require("bundle-text:./routes/views/error-handler/NoEntitySelected.pug"));
    $templateCache.put("/login.html", require("bundle-text:./routes/views/error-handler/login.pug"));
    $templateCache.put("/newVersionAvailable.html", require("bundle-text:./routes/views/error-handler/newVersionAvailable.pug"));
    $templateCache.put("/generic.html", require("bundle-text:./routes/views/error-handler/generic.pug"));

    $templateCache.put("/not-found.html", require("bundle-text:./routes/views/not-found.pug"));

    $templateCache.put("/directives/epi-checkbox-switch.html", require("bundle-text:./common/directives/views/epi-checkbox-switch.pug"));
    $templateCache.put("/directives/epi-checkbox.html", require("bundle-text:./common/directives/views/epi-checkbox.pug"));
    $templateCache.put("/directives/epi-collapse-triangle.html", require("bundle-text:./common/directives/views/epi-collapse-triangle.pug"));
    $templateCache.put("/directives/epi-confirm.html", require("bundle-text:./common/directives/views/epi-confirm.pug"));
    $templateCache.put("/directives/epi-filter.html", require("bundle-text:./common/directives/views/epi-filter.pug"));
    $templateCache.put("/directives/epi-filters-creator.html", require("bundle-text:./common/directives/views/epi-filters-creator.pug"));
    $templateCache.put("/directives/epi-menu.html", require("bundle-text:./common/directives/views/epi-menu.pug"));
    $templateCache.put("/directives/epi-modal.html", require("bundle-text:./common/directives/views/epi-modal.pug"));
    $templateCache.put("/directives/epi-paging.html", require("bundle-text:./common/directives/views/epi-paging.pug"));
    $templateCache.put("/directives/epi-right.html", require("bundle-text:./common/directives/views/epi-right.pug"));
    $templateCache.put("/directives/epi-sliding-panel.html", require("bundle-text:./common/directives/views/epi-sliding-panel.pug"));
    $templateCache.put("/directives/epi-spinner.html", require("bundle-text:./common/directives/views/epi-spinner.pug"));
    $templateCache.put("/directives/fine-uploader.html", require("bundle-text:./common/directives/views/fine-uploader/fine-uploader.pug"));
    $templateCache.put("fine-uploader-gallery", require("bundle-text:./common/directives/views/fine-uploader/fine-uploader-gallery.pug"));
    $templateCache.put("fine-uploader-button", require("bundle-text:./common/directives/views/fine-uploader/fine-uploader-button.pug"));
    $templateCache.put("fine-uploader-empty", require("bundle-text:./common/directives/views/fine-uploader/fine-uploader-empty.pug"));
    $templateCache.put("/directives/loading-indicator.html", require("bundle-text:./common/directives/views/loading-indicator.pug"));
    $templateCache.put("/truterritory/map-reports.html", require("bundle-text:./common/directives/views/map-reports.pug"));
    $templateCache.put("/directives/multi-select.html", require("bundle-text:./common/directives/views/multi-select.pug"));
    $templateCache.put("/directives/progress-indicator.html", require("bundle-text:./common/directives/views/progress-indicator.pug"));
    $templateCache.put("/directives/small-loading-indicator.html", require("bundle-text:./common/directives/views/small-loading-indicator.pug"));

    $templateCache.put("/admin/admin-init.html", require("bundle-text:./routes/admin/views/admin-init.pug"));
    $templateCache.put("/admin/edit-entity-permissions.html", require("bundle-text:./routes/admin/views/edit-entity-permissions.pug"));
    $templateCache.put("/admin/edit-entity.html", require("bundle-text:./routes/admin/views/edit-entity.pug"));
    $templateCache.put("/admin/edit-user-permissions.html", require("bundle-text:./routes/admin/views/edit-user-permissions.pug"));
    $templateCache.put("/admin/edit-user.html", require("bundle-text:./routes/admin/views/edit-user.pug"));
    $templateCache.put("/admin/email-edit.html", require("bundle-text:./routes/admin/views/email-edit.pug"));
    $templateCache.put("/admin/emails.html", require("bundle-text:./routes/admin/views/emails.pug"));
    $templateCache.put("/admin/users-and-entities.html", require("bundle-text:./routes/admin/views/users-and-entities.pug"));

    $templateCache.put("/error/error-init.html", require("bundle-text:./routes/error/views/error-init.pug"));
    $templateCache.put("/error/generic.html", require("bundle-text:./routes/error/views/generic.pug"));

    $templateCache.put("mapping/password-modal.html", require("bundle-text:./routes/mapping/views/password-modal.pug"));
    $templateCache.put("mapping/save-workspace-modal.html", require("bundle-text:./routes/mapping/views/save-workspace-modal.pug"));
    $templateCache.put("/truterritory/sql-editor.html", require("bundle-text:./routes/mapping/views/sql-editor.pug"));
    $templateCache.put("mapping/unsaved-changes-modal.html", require("bundle-text:./routes/mapping/views/unsaved-changes-modal.pug"));

    $templateCache.put("/directives/month-day-select.html", require("bundle-text:./routes/admin/directives/views/month-day-select.pug"));
  },
]).name;
