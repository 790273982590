import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { IFilter } from "../../../types/IMap";
import { ICollection, Property } from "../../../types/ICollection";
import { useGetCollectionFeaturesQuery } from "../../../slices/apiSlice";
import { Paging } from "../../../components/Paging";
import { Button } from "../../../design/Button";

const operators = Object.freeze([
  { name: "=", op: "=" },
  { name: "!=", op: "!=" },
  { name: "IN", op: "IN" },
  { name: "NOT IN", op: "NOT IN" },
  { name: "LIKE", op: "ILIKE" },
  { name: "NOT LIKE", op: "NOT ILIKE" },
  { name: ">", op: ">" },
  { name: "<", op: "<" },
  { name: "≥", op: ">=" },
  { name: "≤", op: "<=" },
]);

export const TruTerritorySideFilters = ({
  onApplyChanges,
  selectedCollection,
}: {
  selectedCollection: ICollection;
  onApplyChanges: (ID: ICollection["ID"], filters: IFilter[]) => void;
}) => {
  const { map } = useAppSelector((state) => state.pages.truterritory);

  const [filters, setFilters] = useState<IFilter[]>();
  const [nameColumn, setNameColumn] = useState<string>();
  const [dataColumn, setDataColumn] = useState<string>();
  const [page, setPage] = useState<number>(1);

  const { data: { data: features, meta } = {} } = useGetCollectionFeaturesQuery({ id: selectedCollection?.ID, filters, limit: 10, page }, { skip: !selectedCollection });

  useEffect(() => {
    if (!map || !selectedCollection) return;

    setFilters(map?.filters[selectedCollection.ID]);
    const usefulColumns = selectedCollection?.properties?.filter((prop) => !prop.hidden && !prop.system) || [];
    setNameColumn(usefulColumns.length ? usefulColumns[0].column : undefined);
    setDataColumn(usefulColumns.length > 1 ? usefulColumns[1].column : undefined);
  }, [map, selectedCollection]);

  return (
    <>
      <h4>Selected collection: {selectedCollection?.name}</h4>
      <FilterCreator filters={filters} onChange={(f) => setFilters(f)} onApplyChanges={(f) => onApplyChanges(selectedCollection.ID, f)} selectedCollection={selectedCollection} />
      <div id="truterritory-collection-list">
        <h5>Total: {meta?.total}</h5>
        <table id="truterritory-feature-data">
          <thead>
            <tr>
              <th colSpan={2}>
                <h4>Name:</h4>
                <div className="select-holder">
                  <select onChange={({ target }) => setNameColumn(target.value)} value={nameColumn}>
                    {selectedCollection?.properties.map((property) => (
                      <option key={`property-${property.column}`} value={property.column}>
                        {property.name}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <h4>Data:</h4>
                <div className="select-holder">
                  <select onChange={({ target }) => setDataColumn(target.value)} value={dataColumn}>
                    {selectedCollection?.properties.map((property) => (
                      <option key={`property-${property.column}`} value={property.column}>
                        {property.name}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {features?.map((feature, i) => (
              <tr key={`feature-${selectedCollection?.ID}-${i}`}>
                <td>{nameColumn && dataColumn && <div className={`map-type ${feature.geometryType.toLowerCase()}`} />}</td>
                <td>{nameColumn && feature[nameColumn]}</td>
                <td>{dataColumn && feature[dataColumn]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {!features && !meta?.total && <div className="empty">No features</div>}
        {meta && features && <Paging pages={meta} onChange={(page) => setPage(page)} />}
      </div>
    </>
  );
};

const FilterCreator = ({
  filters = [],
  onChange = () => {},
  onApplyChanges,
  error,
  selectedCollection,
}: {
  filters?: IFilter[];
  onChange: (filters: IFilter[]) => void;
  onApplyChanges: (filters: IFilter[]) => void;
  error?: string;
  selectedCollection: ICollection;
}) => {
  const addFilter = function () {
    // Add our new filter, which hopefully shouldn't be breaking anything
    onChange(filters.concat([getNewFilter(filters, selectedCollection)]));
  };

  /**
   *
   * @param {array} filters Filters currently being displayed
   * @param {Object} selectedCollection The collection currently
   */
  const getNewFilter = function (filters: IFilter[], selectedCollection: ICollection) {
    const defaultProperty = getDefaultProperty(selectedCollection.properties, filters);
    const jsType = getPgTypeToJs(defaultProperty.type);
    const defaultOperator = jsType === "number" ? ">" : "=";

    return { operator: defaultOperator, column: defaultProperty.name, attribute: defaultProperty.name, value: getJsTypeDefault(jsType) };
  };

  /**
   *
   * @param {array} properties
   * @param {array} filters
   * @return {*}
   */
  const getDefaultProperty = function (properties: Property[], filters: IFilter[]) {
    // If we don't have any filters yet, return the first property
    if (!filters.length) {
      return properties[0];
    }

    // Otherwise add the next property in line to filter by
    // find the last added filter
    const lastFilter = filters.slice(-1)[0];

    // Now find the index of the matching property
    const lastPropertyIndex = properties.findIndex(function (property) {
      return property.column === lastFilter.column;
    });

    // Now, get the index of the "next" property we should use
    const targetIndex = (lastPropertyIndex + 1) % properties.length;

    return properties[targetIndex];
  };

  /**
   *
   * @param {string} pgType
   * @return {string}
   */
  const getPgTypeToJs = function (pgType: string) {
    if (pgType === "varchar" || pgType === "text") {
      return "string";
    } else if (pgType.startsWith("int") || pgType === "numeric" || pgType.startsWith("float") || pgType.endsWith("int")) {
      return "number";
    } else if (pgType === "boolean" || pgType === "bool") {
      return "boolean";
    } else if (pgType.startsWith("timestamp")) {
      return "date";
    } else if (pgType.startsWith("json")) {
      return "json";
    } else if (pgType.endsWith("[]")) {
      return "array";
    } else {
      return "unknown";
    }
  };

  /**
   *
   * @param jsType
   * @return string|number|boolean|Date
   */
  const getJsTypeDefault = function (jsType: string) {
    if (jsType === "string") {
      return "";
    } else if (jsType === "number") {
      return 1;
    } else if (jsType === "boolean") {
      return true;
    } else if (jsType === "date") {
      return new Date().toLocaleString();
    } else if (jsType === "json" || jsType === "jsonb") {
      return "{}";
    } else if (jsType === "array" || jsType === "array") {
      return "[]";
    }

    // In all other cases, return the default for an unknown / "other" case
    return "";
  };

  /**
   * Remove a single filter on the selected collection
   * @param  {int} index
   * @return {void}
   */
  const removeFilter = function (index: number) {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    onChange(newFilters);
  };

  const onFilterChange = function (index: number, filter: IFilter) {
    const newFilters = [...filters];
    newFilters[index] = filter;
    onChange(newFilters);
  };

  return (
    <div className="filters-holder">
      <h4 className="filter-header">Live Filters</h4>
      <form
        className="filter-creator"
        name="filter-creator"
        onSubmit={(e) => {
          onApplyChanges(filters);
          e.preventDefault();
        }}
      >
        {filters.length ? (
          filters.map((filter, index) => (
            <div className="filter" key={`filter-${index}`}>
              <div className="filter" key={`filter-${index}`}>
                <Filter filter={filter} columns={selectedCollection.properties} onChange={(f) => onFilterChange(index, f)} />
              </div>
              <Button className="del" onClick={() => removeFilter(index)} />
              {index === 0 && <Button className="add" onClick={() => addFilter()} />}
            </div>
          ))
        ) : (
          <div className="filter">
            <span className="empty">No filters</span>
            <span className="btn add" onClick={() => addFilter()}></span>
          </div>
        )}
      </form>
      <div className="controls">
        <p className="text-danger error-message">{error}</p>
        <button className="btn" disabled={Boolean(error)} onClick={() => onApplyChanges(filters)} title="Click to apply the current filters to the map">
          Apply Filters
        </button>
      </div>
    </div>
  );
};

const Filter = ({
  filter,
  columns,
  attributeClass,
  operatorClass,
  valueClass,
  onChange,
}: {
  filter: IFilter;
  columns: Property[];
  attributeClass?: string;
  operatorClass?: string;
  valueClass?: string;
  onChange: (filter: IFilter) => void;
}) => (
  <>
    <div className={`filter-select-holder ${attributeClass}`}>
      <select name="column{{$index}}" onChange={({ target }) => onChange({ ...filter, column: target.value })} value={filter.column}>
        {columns.map((property) => (
          <option key={`property-${property.column}`} value={property.column}>
            {property.name}
          </option>
        ))}
      </select>
    </div>
    <div className={`filter-select-holder ${operatorClass}`}>
      <select name="operator{{$index}}" onChange={({ target }) => onChange({ ...filter, operator: target.value })} value={filter.operator}>
        {operators.map((operator) => (
          <option key={`operator-${operator.op}`} value={operator.op}>
            {operator.name}
          </option>
        ))}
      </select>
    </div>
    <input type="text" onChange={({ target }) => onChange({ ...filter, value: target.value })} placeholder="" className={valueClass} value={filter.value + ""} />
  </>
);
