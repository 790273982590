import React, { useEffect, useRef, useState } from "react";
import { IUser } from "../../types/IUser";
import { useGetWorkspaceQuery, useSearchUsersQuery, useUpdateUsersForWorkspaceMutation } from "../../slices/apiSlice";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import { useAppSelector } from "../../hooks/useAppSelector";
import { Button } from "../../design/Button";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { classNameMapper } from "../../utils/classNameMapper";
import { skipToken } from "@reduxjs/toolkit/query";

export const UserSelector = ({ users }: { users: IUser[] }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUserIndex, setSelectedUser] = useState<number>();
  const debouncedSearchQuery = useDebouncedValue(searchQuery);

  const [updateUsersWorkspace] = useUpdateUsersForWorkspaceMutation();

  const dropdownRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOutsideClick(dropdownRef, () => {
    setSearchQuery("");
    setSelectedUser(undefined);
  });

  const { workspaceID } = useAppSelector((state) => state.pages.truterritory);
  const { data: workspace } = useGetWorkspaceQuery(workspaceID ? { ID: workspaceID, with: "collections,users,maps" } : skipToken);

  const { data: queriedUsers, isFetching, isError } = useSearchUsersQuery(debouncedSearchQuery, { skip: debouncedSearchQuery.length < 1 });

  const onInputKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === "Escape") {
      setSearchQuery("");
      setSelectedUser(undefined);
    }

    if (!queriedUsers?.length) return;

    if (key === "ArrowDown") {
      setSelectedUser((prev) => {
        if (prev === undefined) return 0;
        if (prev === queriedUsers.length - 1) return prev;
        return prev + 1;
      });
    } else if (key === "ArrowUp") {
      setSelectedUser((prev) => {
        if (prev === undefined) return 0;
        if (prev === 0) return prev;
        return prev - 1;
      });
    } else if (key === "Enter" && typeof selectedUserIndex !== "undefined") {
      if (queriedUsers[selectedUserIndex]) {
        onAdd([...users, queriedUsers[selectedUserIndex]]);
        setSearchQuery("");
        setSelectedUser(undefined);
      }
    }
  };

  // this only exists just in case the user has selected a user in the dropdown
  //  and then the list of queried users changes to be less than the selected user index
  useEffect(() => {
    if (typeof selectedUserIndex !== "undefined" && queriedUsers && queriedUsers.length - 1 < selectedUserIndex) {
      setSelectedUser(queriedUsers.length - 1);
    }
  }, [queriedUsers, selectedUserIndex]);

  const onAdd = (users: IUser[]) => {
    updateUsersWorkspace({ ID: workspaceID!, users: users.map((u) => u.ID) });
  };

  const onRemove = (userId: number) => {
    const newUsers = users.filter((u) => u.ID !== userId);
    updateUsersWorkspace({ ID: workspaceID!, users: newUsers.map((u) => u.ID) });
  };

  return (
    <div className="user-selector">
      <h4>Users</h4>
      <input
        type="text"
        placeholder="Add a user here..."
        value={searchQuery}
        onKeyDown={onInputKeyDown}
        onChange={({ target }) => {
          setSearchQuery(target.value);
        }}
        ref={inputRef}
      />

      <div className={searchQuery.length ? "open" : ""} ref={dropdownRef}>
        <ul className="dropdown-menu" style={{ position: "absolute", top: "75px", left: "20px" }}>
          {searchQuery.length < 1 && (
            <li>
              <a href="#">
                <i>Keep typing to search...</i>
              </a>
            </li>
          )}
          {isFetching && (
            <li>
              <a href="#">
                <i>Loading...</i>
              </a>
            </li>
          )}
          {isError && (
            <li>
              <a href="#">
                <i>Error loading users</i>
              </a>
            </li>
          )}
          {queriedUsers?.length === 0 && !isFetching && searchQuery.length >= 1 && (
            <li>
              <a href="#">
                <i>No users found</i>
              </a>
            </li>
          )}
          {queriedUsers?.map((u, i) => (
            <li
              key={u.ID}
              className={classNameMapper({ active: i === selectedUserIndex }, "user")}
              onClick={() => {
                onAdd([...users, u]);
                setSearchQuery("");
              }}
            >
              <a href="#">
                {u.fname} {u.lname}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div>
        {users.map((user) => (
          <div className="users" key={user.ID}>
            <div className="text" title={JSON.stringify(user, ["ID", "fname", "lname"], 2)}>
              {user.fname} {user.lname}
            </div>
            {workspace?.userID != user.ID && <Button variant="del" onClick={() => onRemove(user.ID)} />}
          </div>
        ))}
      </div>
    </div>
  );
};
